
    export const RouteConfig = {
        Presentation: { path: "/" },
        Login: { path: "login" },
        DashboardOverview: { path: "dashboard" },
        Agency:{ path: "agency" },
        UserCreate:{ path: "user/create" },
        UserAssignRole:{ path: "user/assignrole" },
        GateWayCharge:{ path: "gatewaySettings" },
        DirectpaymentCreate:{ path: "directpayment/create" },
        DirectpaymentManage:{ path: "directpayment/manage" },
        Report:{ DPay:"report-direct-payments", DTran:"report-payment-transactions" },
        NotFound: { path: "dashboard/404" },
    };