import React, { useState, useEffect } from "react";
import { LoginVM } from "../../../Model/LoginVM";
import AuthClient from "../../../Services/ServiceClient/AuthService";
import ServiceClient from "../../../Services/ServiceFactory";
import { ServiceType } from "../../../Services/Util/ServiceType";
import AppConfig from "../../../Services/Util/AppConfig";
const User = {
  Login: Login,
};

function Login({ setToken,setRoles,token}) {
  const [isvalid, setValid] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const handleLogin = (e) => {
    setShowLoader(true);
    e.preventDefault();

    let loginData = new LoginVM({
      username: e.currentTarget.username.value,
      password: e.currentTarget.password.value,
    });

    ServiceClient.service(ServiceType.Login).Login(
      loginData,
      (res) => {
        AuthClient.setUser(res.data?.token);
        setToken(res.data?.token);
        setInterval(RefreshToken, AppConfig.refreshInterval*1000*60);      
        setRoles(res.data?.userRoles.split(','));
        setShowLoader(false);
        setValid(true);
        AuthClient.setName(res.data?.name);
        AuthClient.setRole(res.data?.userRoles);
        e.target.reset();
      },
      (err) => {
        setValid(false);
        setShowLoader(false);
        e.target.reset();
      }
    );
  };

  function RefreshToken()
  {
    ServiceClient.service(ServiceType.RefreshToken).RefreshToken(
      new Object({
        Token: AuthClient.User()
      }),
      (res) => {
        AuthClient.setUser(res.data?.token);
        setToken(res.data?.token);
      },
      (err) => {
        console.log('error');
      }
    );
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setValid(true);
    }, 3000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <a href="../../index2.html" className="h1">
              <b>Pay</b>Portal
            </a>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Sign in to start your session</p>

            <form onSubmit={handleLogin} method="post">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  name="username"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  name="password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8  d-none">
                  <div className="icheck-primary">
                    <input type="checkbox" id="remember" />
                    <label htmlFor="remember">Remember Me</label>
                  </div>
                </div>
                <div className="col-4">
                  {!showLoader ? (
                    <button type="submit" className="btn btn-primary btn-block">
                      Sign In
                    </button>
                  ) : (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="sr-only">Loading...</span>
                    </button>
                  )}
                </div>
              </div>
            </form>
            <p className="mb-1 d-none">
              <a href="forgot-password.html">I forgot my password</a>
            </p>
            <p className="mb-0  d-none">
              <a href="register.html" className="text-center">
                Register a new membership
              </a>
            </p>
          </div>
        </div>
        {!isvalid ? (
          <div className="alert alert-danger">Invalid Username or Password</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default User;
