import React, { useEffect, useState } from "react";
import UserRoleCrud from "./UserRoleCrud";
import UserRoleView from "./UserRoleView";

const UserManager = {
  AssignRole: {
    Content: User,
  },
};

function User({ selPage }) {
 
  const [editUserRole, setEditUserRole] = useState();
  const [userList, setUserList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    selPage("Roles Management");
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <UserRoleCrud.Create.Content
            setUserList={setUserList}
            showLoader={showLoader}    
            setShowLoader={setShowLoader}   
            userList={userList}    
            selUserRole= {editUserRole}
            setRolesList={setRolesList}            
            rolesList={rolesList}
            />
            {/* <UserRoleView.Create.Content
            userList={userList}            
            /> */}
          </div>
        </div>
      </div>
    </>
  );
}
  
 
  
  export default UserManager;