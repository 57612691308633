import HttpClient from "../APIService/RestAPI";

const GatewayServiceClient = {
    ChargeList: getCharge,
    POST: postCharge,
    PUT: putCharge,
    DELETE: deleteCharge,
  };
  
  function getCharge(callback, errorcallback) {
    const URL = "api/charge/service-charges";
  
    var config = { "Access-Control-Allow-Origin": "*" };  
  
    return HttpClient.GET(URL, config, callback, errorcallback);
  }
  
  function postCharge(data, callback, errorcallback) {
    const URL = `api/charge/service-charges`;
  
    var config = { "Access-Control-Allow-Origin": "*" };
  
    return HttpClient.POST(URL, data, config, callback, errorcallback);
  }
  
  function putCharge(data,callback, errorcallback) {
    const URL = "api/charge/service-charges";
  
    var config = { "Access-Control-Allow-Origin": "*" };
  
    return HttpClient.PUT(URL,data, config, callback, errorcallback);
  }
  
  function deleteCharge(data,callback, errorcallback) {
    const URL = "api/charge/service-charge/"+data;
  
    var config = { "Access-Control-Allow-Origin": "*" };
  
    return HttpClient.DELETE(URL, config, callback, errorcallback);
  }
  
  export default GatewayServiceClient;