import HttpClient from "../APIService/RestAPI";

const UserServiceClient = {
  UserList: getUsers,
  AgencyUser:getAgencyUsers,
  POST: postUsers,
  PUT: putUsers,
  DELETE: deleteUsers,
};

function getUsers(callback, errorcallback) {
  const URL = "api/user/manage-users";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.GET(URL, config, callback, errorcallback);
}
function getAgencyUsers(data,callback, errorcallback) {
  const URL = "api/user/manage-users/"+data+"/agency/user";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.GET(URL, config, callback, errorcallback);
}

function postUsers(data, callback, errorcallback)  {
  const URL = `api/user/manage-users`;

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.POST(URL, data, config, callback, errorcallback);
}

function putUsers(data,callback, errorcallback) {
  const URL = "api/user/manage-users";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.PUT(URL,data, config, callback, errorcallback);
}

function deleteUsers(data,callback, errorcallback) {
  const URL = "api/user/manage-users/"+data;

  var config = { "Access-Control-Allow-Origin": "*" };
  return HttpClient.DELETE(URL, config, callback, errorcallback);
}

export default UserServiceClient;
