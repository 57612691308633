export class UserVM {
    constructor({userId, userName,userPassword,saluation,firstName,middleName,lastName,
        email, mobileCode, mobileNumber, agencyId, isActive}) {
      this.userId = userId;
      this.userName = userName;
      this.userPassword=userPassword;
      this.saluation=saluation;
      this.firstName=firstName;
      this.middleName=middleName;
      this.lastName=lastName;
      this.email = email;
      this.mobileCode = mobileCode;
      this.mobileNumber = mobileNumber;
      this.agencyId = agencyId;
      this.isActive = isActive;
    }
  }