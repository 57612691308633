import React, { useEffect, useState } from "react";
import ServiceClient from "../../../Services/ServiceFactory";
import { ServiceType } from "../../../Services/Util/ServiceType";
import { UserRoleVM } from "../../../Model/UserRoleVM";
import { MessageType } from "../../../Services/Util/MessageType";

const UserRoleCrud = {
  Create: {
    Content: Content,
  },
};

function Content({
  setUserList,
  showLoader,
  setShowLoader,
  userList,
  selUserRole,
  setRolesList,
  rolesList
}) {
  const [message, setMessage] = useState("");
  const [selUser, setselUser] = useState("");
  const [selAgency, setSelAgency] = useState("");
  const [agencyList, setAgencyList] = useState([]);
  const [selUserRoles, setselUserRoles] = useState([]);
  const [checkedState, setCheckedState] = useState({});
  
  setShowLoader(false);

  function handleUserchange(event) {
    setselUser(event.target.value);
    setselUserRoles(null);
  }

  function handleAgencychange(event) {
    setSelAgency(event.target.value);

    let agency=event.target.value;

    setCheckedState({});

    ServiceClient.service(ServiceType.UserService).AgencyUser(
      agency,
    (res) => {
      setUserList(res?.data?.data.filter(x => x.isActive == true));
      setShowLoader(false);
    },
    (err) => {
      console.log(err);
    }
  );
  
  }

  

  function handleReset() {
  }

  useEffect(() => {
    if(selUserRoles == null && selUser != null && selUser > 0){
      ServiceClient.service(ServiceType.RoleService).UserRolesList(
        selUser,
        (res) => {
          setselUserRoles(res?.data?.data);
          var lscheckedState = {};
          for(var ckRoles = 0; ckRoles < rolesList.length; ckRoles++){
            lscheckedState[rolesList[ckRoles]] = false;

            for(var ckRolesE = 0; ckRolesE < res?.data?.data.length; ckRolesE++){
              if(rolesList[ckRoles] == res?.data?.data[ckRolesE].rolename){
                lscheckedState[rolesList[ckRoles]] = true;
              }
            }

          }
          
          setCheckedState(lscheckedState);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [checkedState,selUserRoles,rolesList]);

  useEffect(() => {
    ServiceClient.service(ServiceType.RoleService).RoleList(
      (res) => {
        setRolesList(res?.data?.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [setRolesList]);


  const handleOnChange = (rolename) => {
    var checkedStatenew = {};
    for (let key in checkedState) {
      checkedStatenew[key] = checkedState[key];
      if(key == rolename){
        checkedStatenew[key] = !checkedStatenew[key];
      }
    }

    setCheckedState(checkedStatenew);
  }



  useEffect(() => {
     
    ServiceClient.service(ServiceType.AgencyService).AgencyList(
      (res) => {
        setAgencyList(res?.data?.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [message]);


  


  const formSubmit = (e) => {
    e.preventDefault();
    let roleListToSave = [];
    for (let key in checkedState) {
      if(checkedState[key] == true){

          let roleData = new UserRoleVM({
            userId: selUser != null ? selUser : 0,
            rolename: key,
            isActive: true
          });

          roleListToSave.push(roleData);
      }
    }

    if (roleListToSave != null && roleListToSave.length > 0) {
      ServiceClient.service(ServiceType.RoleService).POST(
        roleListToSave,
        (res) => {
          setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
        },
        (err) => {
          setShowLoader(false);
          console.log(err);
        }
      );
    }

  }
  
  return (
    <div className="card card-primary">
      <div className="card-header">
        <h3 className="card-title">Assign User Roles</h3>
        <div className="card-tools"></div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <form onSubmit={formSubmit}>
            <div className="form-group">
                <label>Agency</label>
                <select
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  data-select2-id="1"
                  tabIndex="-1"
                  aria-hidden="true"
                  value={selAgency}
                  name="userId"
                  onChange={handleAgencychange}
                >
                  <option key={-1} defaultValue={-1} value={-1}>
                    Select
                  </option>
                  {agencyList.filter(x => x.isActive == true).map((obj) => (
                    <option
                      value={obj.agencyId}
                      key={obj.agencyId}
                      defaultValue={obj.agencyId}
                    >
                      {obj.agencyName}
                    </option>
                  ))}
                </select>
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>

              <div className="form-group">
                <label>User</label>
                <select
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  data-select2-id="1"
                  tabIndex="-1"
                  aria-hidden="true"
                  value={selUser}
                  name="userId"
                  onChange={handleUserchange}
                >
                  <option key={-1} defaultValue={-1} value={-1}>
                    Select
                  </option>
                  {userList.filter(x => x.isActive == true).map((obj) => (
                    <option
                      value={obj.userId}
                      key={obj.userId}
                      defaultValue={obj.userId}
                    >
                      {obj.userName}
                    </option>
                  ))}
                </select>
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>

              <div className="form-group clearfix">
                <label></label>

                {rolesList.map((item, index) => (
                  <div className="icheck-primary d-inline" key={index}>
                    <input
                      id={index}
                      type="checkbox"
                      key={index}
                      name="roles"
                      value={item}
                      checked={checkedState[item] == true ? true : false }
                      onChange={() => handleOnChange(rolesList[index])}
                    />
                    <label htmlFor={index}>{item}</label>
                    <label></label>
                  </div>
                ))}


              </div>

              {message !== null &&
                message !== "" &&
                message === MessageType.Success ? (
                <div className="alert alert-success">Role Updated</div>
              ) : message != null && message === MessageType.Fail ? (
                <div className="alert alert-danger">Role Updation Failed</div>
              ) : (
                ""
              )}

              {!showLoader ? (
                <button type="submit" className="btn btn-primary btn-space">
                  {selUserRole != null ? "Update" : "Submit"}
                </button>
              ) : (
                <button
                  className="btn btn-primary btn-space"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              )}

              <button
                type="reset"
                className="btn btn-danger btn-space"
                onClick={() => handleReset()}
              >
                Reset
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserRoleCrud;