export class GateWayChargeVM {
    constructor({serviceChargeId,agencyId,paymentGatewayId, amount,currencyCode,isPercentage,isActive}) {
      this.serviceChargeId=serviceChargeId;
      this.agencyId=agencyId;
      this.paymentGatewayId = paymentGatewayId;
      this.amount = amount;
      this.currencyCode=currencyCode;
      this.isPercentage=isPercentage;
      this.isActive = isActive
    }
  }
