export const ServiceType = {
  AgencyService: 1,

  UserService: 2,

  Login: 3,

  MasterService: 4,

  GatewayService: 5,

  RoleService: 6,

  DirectPaymentService: 7,

  ReportService: 8,

  RefreshToken:9
};

export const StorageType = {
  Local: 1,

  Session: 2,

  Cookie: 3,

  Redis: 4,
};
