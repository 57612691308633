import React, { useState, useEffect } from "react";
import ServiceClient from "../../../Services/ServiceFactory";
import { Fomatter } from "../../../Services/Util/Formatter";
import { ServiceType } from "../../../Services/Util/ServiceType";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import "../DirectPayment/Paginate.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "../../../TableStyle.css";
//import AppConfig from "../../../Services/Util/AppConfig";

function DTransactionReport({ selPage }) {
  const [dTransactionList, setTransactionList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [agencyList, setAgencyList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selAgency, setselAgency] = useState();
  const [selUser, setselUser] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const [directPaymentId, setDirectPaymentId] = useState();
  const [paymentReference, setPaymentReference] = useState();
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  function refresh() {
    setShowLoader(true);
    GetReport(
      selAgency,
      selUser,
      fromDate,
      toDate,
      paymentStatus,
      directPaymentId,
      paymentReference
    );
  }

  function handleAgencychange(event) {
    setselAgency(event.target.value);
  }

  function handleUserchange(event) {
    setselUser(event.target.value);
  }

  function handlePStatuschange(event) {
    setPaymentStatus(event.target.value);
  }

  function handleDPayIdchange(event) {
    setDirectPaymentId(event.target.value);
  }

  function handlePayReffchange(event) {
    setPaymentReference(event.target.value);
  }

  const handleFromDateSelect = (date) => {
    if (!(date instanceof Date)) {
      date = new Date();
    }
    date = DateFromUTC(date);
    setFromDate(date);
    if (toDate == undefined || toDate < date) {
      setToDate(date);
    }
  };

  const handleToDateSelect = (date) => {
    if (!(date instanceof Date)) {
      date = new Date();
    }
    date = DateFromUTC(date);
    setToDate(date);
    if (fromDate == undefined) {
      setFromDate(date);
    }
    GetReport(selAgency, selUser, fromDate, date);
  };

  const handleFilter = (event) => {
    event.preventDefault();
    setShowLoader(true);
    GetReport(
      event.currentTarget.agencyId.value,
      event.currentTarget.userId.value,
      fromDate,
      toDate,
      event.currentTarget.paymentStatus.value,
      event.currentTarget.directPaymentId.value,
      event.currentTarget.paymentReference.value
    );
  };

  useEffect(() => {
    selPage("Payment Transactions");
  }, [selPage]);

  useEffect(() => {
    ServiceClient.service(ServiceType.AgencyService).AgencyList(
      (res) => {
        setAgencyList(res?.data?.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    if (selAgency != undefined) {
      ServiceClient.service(ServiceType.UserService).AgencyUser(
        selAgency,
        (res) => {
          setUserList(res?.data?.data);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [selAgency]);

  function GetReport(
    agencyId,
    userId,
    DateFrom,
    DateTo,
    PaymentStatus,
    DirectPaymentId,
    PaymentReference
  ) {
    let rptData = {
      isFileReport: false,
      fileType: 0,
      reportData: {
        agencyId: agencyId,
        userId: userId,
        paymentStatus: PaymentStatus,
        minData: DateFrom,
        maxData: DateTo,
        directPaymentId:
          DirectPaymentId === "" || isNaN(DirectPaymentId)
            ? 0
            : DirectPaymentId,
        paymentReference: PaymentReference,
      },
    };

    ServiceClient.service(ServiceType.ReportService).DTransactionList(
      rptData,
      (res) => {
        setTransactionList(res?.data?.data);
        setShowLoader(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const exportData = [
      ...apiData.map(
        ({
          DirectPaymentId,
          PaymentGatewayName,
          Amount,
          CurrencyCode,
          PaidDate,
          CreatedBy,
          AgencyName,
          CustomerName,
          PaymentStatusText,
          PayReference,
          PaymentId,
          Result,
          TransID,
          Auth,
        }) => {
          return {
            Payment_Id: DirectPaymentId,
            PaymentGateway: PaymentGatewayName,
            Amount: Amount,
            Currency: CurrencyCode,
            PaidDate: PaidDate,
            CreatedBy: CreatedBy,
            AgencyName: AgencyName,
            CustomerName: CustomerName,
            PaymentStatus: PaymentStatusText,
            PayReference: PayReference,
            PaymentId: PaymentId,
            GatewayStatus: Result,
            TransactionId: TransID,
            AuthId: Auth,
          };
        }
      ),
    ];

    const currTime = new Date();
    const postFileName =
      "_" +
      currTime.getDate() +
      (currTime.getMonth() + 1) +
      currTime.getFullYear() +
      currTime.getHours() +
      currTime.getMinutes();
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + postFileName + fileExtension);
  };

  useEffect(() => {
    const itemsPerPage = 10;
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(dTransactionList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(dTransactionList.length / itemsPerPage));
  }, [itemOffset, dTransactionList]);

  const handlePageClick = (event) => {
    const itemsPerPage = 10;
    const newOffset = (event.selected * itemsPerPage) % dTransactionList.length;
    setItemOffset(newOffset);
  };

  function getPaymentStatus(status) {
    switch (status) {
      case 0:
        return "Not Paid";
      case 1:
        return "Paid";
      case 2:
        return "Failed";
      case 3:
        return "PartiallyPaid";
      case 4:
        return "Initiated";
      case 5:
        return "Cancelled";
    }
  }

  useEffect(() => {
    var date = date = DateFromUTC(new Date());
    var firstDay =  DateFromUTC(new Date(date.getFullYear(), date.getMonth(), 1));
    setFromDate(firstDay);
    setToDate(date);
    setShowLoader(true);
    GetReport(
      selAgency,
      selUser,
      firstDay,
      date,
      paymentStatus,
      directPaymentId,
      paymentReference
    );
  }, []);

  const DateFromUTC = (custDate) => {
    return new Date(Date.UTC(custDate.getFullYear(), custDate.getMonth(), custDate.getDate()));
  }

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Filter Report</h3>
      </div>

      <form onSubmit={handleFilter}>
        <div className="card-body">
          <div className="row">
            <div className="col-md-3 col-xs-12">
              <div className="form-group">
                <label>Agency</label>
                <select
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  data-select2-id="1"
                  tabIndex="1"
                  aria-hidden="true"
                  name="agencyId"
                  onChange={handleAgencychange}
                >
                  <option key={-1} defaultValue={-1} value={-1}>
                    All
                  </option>
                  {agencyList
                    .filter((x) => x.isActive == true)
                    .map((obj) => (
                      <option
                        value={obj.agencyId}
                        key={obj.agencyId}
                        defaultValue={obj.agencyId}
                      >
                        {obj.agencyName}
                      </option>
                    ))}
                </select>
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="form-group">
                <label>User</label>
                <select
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  data-select2-id="1"
                  tabIndex="1"
                  aria-hidden="true"
                  name="userId"
                  onChange={handleUserchange}
                >
                  <option key={-1} defaultValue={-1} value={-1}>
                    All
                  </option>
                  {userList
                    .filter((x) => x.isActive == true)
                    .map((obj) => (
                      <option
                        value={obj.userId}
                        key={obj.userId}
                        defaultValue={obj.userId}
                      >
                        {obj.userName}
                      </option>
                    ))}
                </select>
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="form-group">
                <label>From Date</label>
                <div
                  className="input-group date"
                  id="reservationdatetime"
                  data-target-input="nearest"
                >
                  <DatePicker
                    name="fromDate"
                    className="form-control"
                    dateFormat="dd-MMM-yyyy"
                    selected={fromDate}
                    onSelect={handleFromDateSelect} //when day is clicked
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="form-group">
                <label>To Date</label>
                <div
                  className="input-group date"
                  id="reservationdatetime"
                  data-target-input="nearest"
                >
                  <DatePicker
                    name="ToDate"
                    className="form-control"
                    dateFormat="dd-MMM-yyyy"
                    minDate={fromDate}
                    selected={toDate}
                    onSelect={handleToDateSelect} //when day is clicked
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="form-group">
                <label>Status</label>
                <select
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  data-select2-id="1"
                  tabIndex="1"
                  aria-hidden="true"
                  name="paymentStatus"
                  onChange={handlePStatuschange}
                >
                  <option key={-1} defaultValue={-1} value={-1}>
                    All
                  </option>
                  <option value={0} key={0} defaultValue={0}>
                    {" "}
                    {getPaymentStatus(0)}{" "}
                  </option>
                  <option value={1} key={1} defaultValue={1}>
                    {" "}
                    {getPaymentStatus(1)}{" "}
                  </option>
                  <option value={2} key={2} defaultValue={2}>
                    {" "}
                    {getPaymentStatus(2)}{" "}
                  </option>
                  <option value={5} key={5} defaultValue={5}>
                    {" "}
                    {getPaymentStatus(5)}{" "}
                  </option>
                </select>
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="form-group">
                <label>ID</label>
                <input
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  tabIndex="1"
                  aria-hidden="true"
                  name="directPaymentId"
                  onChange={handleDPayIdchange}
                />
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="form-group">
                <label>Payment Reference</label>
                <input
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  tabIndex="1"
                  aria-hidden="true"
                  name="paymentReference"
                  onChange={handlePayReffchange}
                />
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="form-group">
                <label>&nbsp;</label>
                <div
                  className="input-group date"
                  id="reservationdatetime"
                  data-target-input="nearest"
                >
                  <button
                    className="btn btn-primary btn-space"
                    style={{ width: "100%" }}
                    tabIndex="1"
                    aria-hidden="true"
                    name=""
                    type="submit"
                    value="Filter"
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-header">
          <h3 className="card-title">
            <b>Payment Report</b>
          </h3>
          &nbsp;&nbsp;&nbsp;
          <i
            className="fa fa-sync-alt"
            title="Refresh"
            onClick={() => refresh()}
          ></i>
          &nbsp;&nbsp;&nbsp;
          <i
            className="fa fa-file-excel"
            title="Export"
            onClick={() => exportToCSV(dTransactionList, "PaymentTransactions")}
          ></i>
        </div>
      </form>

      <div className="card-body">
        {showLoader ? (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : dTransactionList.length > 0 ? (
          <div className="table-responsive">
            <table className="table text-center">
              <thead>
                <tr className="t-head-bg text-light">
                  <th scope="col">#</th>
                  <th scope="col">Gateway</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Agency</th>
                  <th scope="col">Created By</th>
                  <th scope="col">Paid Date</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Status</th>
                  <th scope="col">Result</th>
                  <th scope="col">Reference</th>
                </tr>
              </thead>
              <tbody className="tbody_strip">
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.DirectPaymentId}</td>
                    <td>{item.PaymentGatewayName}</td>
                    <td>
                      {Fomatter.CurrencyFormat(item.Amount, item.CurrencyCode)}
                    </td>
                    <td>{item.AgencyName}</td>
                    <td>{item.CreatedBy}</td>
                    <td>
                      {Fomatter.Dateformat(item.CreatedDate, "dd-MMM-yyyy")}
                    </td>
                    <td>{item.CustomerName}</td>
                    <td>
                      <span
                        className={
                          item.PaymentStatus === 0
                            ? "notpaid"
                            : item.PaymentStatus === 1
                            ? "paid"
                            : item.PaymentStatus === 2 ||
                              item.PaymentStatus === 5
                            ? "notpaid"
                            : "notpaid"
                        }
                      >
                        {item.PaymentStatusText}
                      </span>
                    </td>
                    <td>{item.Result}</td>
                    <td>{item.Reference}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="alert alert-danger"></div>
        )}
      </div>
      <div className="div-paginate">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<<"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}
export default DTransactionReport;
