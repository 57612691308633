import {HelmetProvider,  Helmet } from "react-helmet-async";
export default function RenderScript() {
  return (
    <HelmetProvider>
    <Helmet>
      <script>$.widget.bridge('uibutton', $.ui.button)</script>
      <script src="./assets/js/bootstrap.bundle.min.js"></script>
      <script src="./assets/js/jquery.knob.min.js"></script>
      <script src="./assets/js/moment.min.js"></script>
      <script src="./assets/js/jquery.overlayScrollbars.min.js"></script>
      <script src="./assets/js/adminlte.js"></script>
      <script src="./assets/js/demo.js"></script>
      <script src="./assets/js/pages/dashboard.js"></script>
    </Helmet>
    </HelmetProvider>
  );
}
