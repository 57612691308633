import StorageClient from "../StorageFactory";
import { StorageType } from "../Util/ServiceType";

let storage = StorageClient.service(StorageType.Session);

function generateUUID() {
  var d = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

let Id = generateUUID();

function getAuthId() {
  var Uclient_id = storage.Get("Uclient_id");

  let userExist = storage.Get(Uclient_id);

  return userExist !== undefined ? userExist : "";
}

function getName() {
  var u_clientId = storage.Get("u_name_client_Id");

  let userName = storage.Get(u_clientId);

  return userName;
}

function getRole() {
  var role = storage.Get("roles");

  let roles = storage.Get(role);

  return roles;
}

function setName(userName) {
  let uID = generateUUID();
  storage.Set("u_name_client_Id", uID);
  storage.Set(uID, userName);
}

function setAuthId(token) {
  storage.Set("Uclient_id", Id);
  storage.Set(Id, token);
}

function setRole(roles) {
  let uID = generateUUID();
  storage.Set("roles", uID);
  storage.Set(uID, roles);
}

const AuthClient = {
  User: getAuthId,
  setUser: setAuthId,
  setName: setName,
  Name : getName,
  setRole:setRole,
  getRole:getRole
};

export default AuthClient;
