// import { createClient } from "redis";

// let redisClient = (async () => {
//   const client = createClient({
//     url: "redis://alice:foobared@awesome.redis.server:6380",
//   });

//   client.on("error", (err) => console.log("Redis Client Error", err));

//   const subscriber = client.duplicate();

//   await subscriber.connect();

//   return subscriber;
// })();

async function getItem(key) {
  // await redisClient.subscribe(key, (message) => {
  //   return message;
  // });
}

async function setItem(key, data) {
//   await redisClient.publish(key, data);
 }

const RedisStore = {
  Get: getItem,

  Set: setItem,
};

export default RedisStore;
