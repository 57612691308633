import React, { useEffect, useState } from "react";
import ServiceClient from "../Services/ServiceFactory";
import { ServiceType } from "../Services/Util/ServiceType";
import { PaymentStatusType } from "../Services/Util/PaymentStatusType";

export default function AgencyDashBoardCount({token}) {
  const [dashBoardCount, setDashBoardCount] = useState([]); 


  async function DashBoradCount() {
    ServiceClient.service(ServiceType.ReportService).DashBoradCountList(
      (res) => {
        setDashBoardCount(res?.data?.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }
  
  useEffect(() => {
    if(token)
    {
      DashBoradCount()
    }
    
   }, [token]);
  
  


//   setInterval(() => {
//     DashBoradCount();
//  }, 1000);


  return (
    <div className="row">
      <div className="col-lg-3 col-6">
        <div className="small-box bg-info">
          <div className="inner">
            <h3>
              {dashBoardCount.find(
                (t) => t.PaymentStatus === PaymentStatusType.NotPaid
              )?.PCount}
            </h3>

            <p>Payment Created</p>
          </div>
          <div className="icon">
            <i className="ion ion-bag"></i>
          </div>
          <a href="#f" className="small-box-footer">
            More info <i className="fas fa-arrow-circle-right"></i>
          </a>
        </div>
      </div>
      <div className="col-lg-3 col-6">
        <div className="small-box bg-success">
          <div className="inner">
            <h3>
              {dashBoardCount.find(
                (t) => t.PaymentStatus === PaymentStatusType.Paid
              )?.PCount}
            </h3>

            <p>Payment Completed</p>
          </div>
          <div className="icon">
            <i className="ion ion-stats-bars"></i>
          </div>
          <a href="#f" className="small-box-footer">
            More info <i className="fas fa-arrow-circle-right"></i>
          </a>
        </div>
      </div>
      <div className="col-lg-3 col-6">
        <div className="small-box bg-warning">
          <div className="inner">
            <h3>
              {dashBoardCount.find(
                (t) => t.PaymentStatus === PaymentStatusType.Cancelled
              )?.PCount}
            </h3>

            <p>Payment Cancelled</p>
          </div>
          <div className="icon">
            <i className="ion ion-person-add"></i>
          </div>
          <a href="#f" className="small-box-footer">
            More info <i className="fas fa-arrow-circle-right"></i>
          </a>
        </div>
      </div>
      <div className="col-lg-3 col-6">
        <div className="small-box bg-danger">
          <div className="inner">
            <h3>
              {dashBoardCount.find(
                (t) => t.PaymentStatus === PaymentStatusType.Failed
              )?.PCount}
            </h3>

            <p>Payment Failed</p>
          </div>
          <div className="icon">
            <i className="ion ion-pie-graph"></i>
          </div>
          <a href="#f" className="small-box-footer">
            More info <i className="fas fa-arrow-circle-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
