import axios from "axios";
import AuthClient from "../ServiceClient/AuthService";
import AppConfig from "../Util/AppConfig";

const customAxios = axios.create({
  baseURL: AppConfig.baseURL,

  timeout: 10000,
});

const requestHandler = (request) => {
  let token = AuthClient.User();

  request.headers.Authorization = `Bearer ${token}`;

  return request;
};

const responseHandler = (response) => {
  if (response.status === 401) {
    window.location = "/login";
  }

  return response;
};

const errorHandler = (error) => {
  return Promise.reject(error);
};
customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default customAxios;
