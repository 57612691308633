import axios from "./AxiosInterceptor";

const HttpClient = {
  GET: GET,
  POST: POST,
  PUT: PUT,
  DELETE: DELETE,
};

function GET(url, config, callback, errorcallback) {
  axios
    .get(url, config)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

function POST(url, data, config, callback, errorcallback) {
  axios
    .post(url, data, config)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

function PUT(url, data, config, callback, errorcallback) {
  axios
    .put(url, data, config)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

function DELETE(url, config, callback, errorcallback) {
  axios
    .delete(url,  config)
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}

export default HttpClient;
