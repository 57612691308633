import HttpClient from "../APIService/RestAPI";

const DireactPaymentServiceClient = {
  DirectPaymentList: getDirectPaymentList,
  CreateDirectPayment: CreatePayment,
  DirectPaymentCopyLink: GetDirectPaymentCopyLink,
  ShareDPLink: ShareDirectPaymentCopyLink,
  DELETE: deleteDirectPay,
  PUT: UpdatePayment,
  GET: FetchADirectPayment,
  PRINT: PrintReciept,
};

function getDirectPaymentList(data,callback, errorcallback) {
  
  const URL = "api/direct-payment/manage-direct-payments";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.POST(URL, data, config, callback, errorcallback);
}

function FetchADirectPayment(data, callback, errorcallback) {
  const URL = "api/direct-payment/manage-direct-payment/" + data;

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.GET(URL, config, callback, errorcallback);
}
function PrintReciept(data, callback, errorcallback) {
  const URL = "api/direct-payment/manage-direct-payment/" + data + "/print-html-internal";
  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.GET(URL, config, callback, errorcallback);
}

function CreatePayment(data, callback, errorcallback) {
  const URL = `api/direct-payment/manage-direct-payment`;

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.POST(URL, data, config, callback, errorcallback);
}

function UpdatePayment(data, callback, errorcallback) {
  const URL = `api/direct-payment/manage-direct-payment`;

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.PUT(URL, data, config, callback, errorcallback);
}

function GetDirectPaymentCopyLink(data, callback, errorcallback) {
  const URL = "api/direct-payment/manage-direct-payment/" + data + "/Copy";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.GET(URL, config, callback, errorcallback);
}

function ShareDirectPaymentCopyLink(data, callback, errorcallback) {
  const URL =
    "api/direct-payment/manage-direct-payment/payment/" +
    data.id +
    "/customer/" +
    data.address +
    "/share/medium/" +
    data.medium+
    "/"+ data.culture;
   
  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.GET(URL, config, callback, errorcallback);
}
function deleteDirectPay(data, callback, errorcallback) {
  const URL = "api/direct-payment/manage-direct-payment/" + data;

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.DELETE(URL, config, callback, errorcallback);
}

export default DireactPaymentServiceClient;
