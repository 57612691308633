import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ServiceClient from "../../../Services/ServiceFactory";
import { ServiceType } from "../../../Services/Util/ServiceType";
import { MessageType } from "../../../Services/Util/MessageType";
import { CustomerVM, DirectPaymentVM } from "../../../Model/DirectPaymentVM";
import { Fomatter } from "../../../Services/Util/Formatter";
const CreateDirectPayment = {
  Content: Content,
};

function Content({
  SelDirectPayment,
  setEditDirectPayment,
  setDirectPaymentList,
  setShowLoader,
  showLoader,
  deleteDirectPayment,
  setDeleteDirectPayment,
  setAgencyList,
  setIdEditedDPay,
  idEditedDPay,
  setSavedItem,
  isRefresh,
  setUserList,
  fromDate,
  toDate
}) {
  const [message, setMessage] = useState("");
  const [selsaluation, setselsaluation] = useState("");
  const [selmobileCode, setselmobileCode] = useState("");
  const [mobileCodes, setmobileCodes] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [startExpDate, setStartExpDate] = useState((new Date()).setDate((new Date()).getDate() + 1));

  const userTitle = [
    { value: "0", label: "Mr" },
    { value: "1", label: "Mrs" },
    { value: "2", label: "Mstr" },
  ];

  function handleSaluationchange(event) {
    setselsaluation(event.target.value);
  }

  function handleReset(e) {
    setEditDirectPayment(null);
   // setselsaluation("");
    setStartExpDate((new Date()).setDate((new Date()).getDate() + 1));
  }

  const handleMobilecodechange = (event) =>
    setselmobileCode(event.target.value);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const handleDateSelect = (date) => {
    setStartExpDate(new Date(date));
    if(SelDirectPayment != null)
    {
      SelDirectPayment.expiry = new Date(date);
    }
  };

  useEffect(() => {
    if (SelDirectPayment != null) {
      //setselsaluation(SelDirectPayment.customer?.saluation);
      setIsChecked(SelDirectPayment.isActive);
     // setselmobileCode(SelDirectPayment.customer?.mobileCode);
      window.scrollTo(0, 0);
    }
  }, [SelDirectPayment]);

  useEffect(() => {
    ServiceClient.service(ServiceType.AgencyService).AgencyList(
      (res) => {
        setAgencyList(res?.data?.data);
      },
      (err) => {
        console.log(err);
      }
    );

   ServiceClient.service(ServiceType.UserService).UserList(
      (res) => {
        setUserList(res?.data?.data.filter(x => x.isActive == true));
        setShowLoader(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {   
    let rptData = {
      fromDate : fromDate,
      toDate : toDate,
    }
    ServiceClient.service(ServiceType.DirectPaymentService).DirectPaymentList(
      rptData,
      (res) => {
        setDirectPaymentList(
          res?.data?.data.filter((x) => x.isActive === true || x.isActive === false)
        );
        setShowLoader(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [message,isRefresh]);

  useEffect(() => {
    if (deleteDirectPayment != null) {
      ServiceClient.service(ServiceType.DirectPaymentService).DELETE(
        deleteDirectPayment,
        (res) => {
          setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
        },
        (err) => {
          console.log(err);
        }
      );
      setDeleteDirectPayment(null);
    }
  }, [deleteDirectPayment, setDeleteDirectPayment]);

  useEffect(() => {
    if (idEditedDPay != null && idEditedDPay > 0) {
      ServiceClient.service(ServiceType.DirectPaymentService).GET(
        idEditedDPay,
        (res) => {
          setIdEditedDPay(null);
          setEditDirectPayment(res?.data?.data);
        },
        (err) => {
          setIdEditedDPay(null);
          console.log(err);
        }
      );
      setDeleteDirectPayment(null);
    }
  }, [idEditedDPay, setEditDirectPayment, setIdEditedDPay]);


  useEffect(() => {
    ServiceClient.service(ServiceType.MasterService).Mobilecodes(
      (res) => {
        setmobileCodes(res?.data.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 10);
    return () => clearTimeout(timer);
  });

  const formSubmit = (e) => {
    e.preventDefault();

    let agencyData = new DirectPaymentVM({
      amount: e.currentTarget.amount.value,
      currencyCode: "KWD",
      remark: '',
      isActive: e.currentTarget.isActive.value,
      expiry: e.currentTarget.expiry.value,
      directPaymentId: SelDirectPayment != null ? SelDirectPayment.directPaymentId : 0,
      customerId: SelDirectPayment != null ? SelDirectPayment.customerId : 0,
      agencyId: SelDirectPayment != null ? SelDirectPayment.agencyId : 0,
      customer: new CustomerVM({
        CustomerId:SelDirectPayment != null ? SelDirectPayment.customer.customerId : 0,
        saluation: '',
        customerName: e.currentTarget.customerName.value,
        email: '',
        phoneNumber: '',
        address: "",
        isActive: true,
      }),
    });
    if (SelDirectPayment === undefined || SelDirectPayment === null) { 
      ServiceClient.service(
        ServiceType.DirectPaymentService
      ).CreateDirectPayment(
        agencyData,
        (res) => {
          setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
          setShowLoader(false);
          handleReset();
          e.target.reset();

          let rptData = {
            fromDate : fromDate,
            toDate : toDate,
          }
          ServiceClient.service(ServiceType.DirectPaymentService).DirectPaymentList(
            rptData,
            (res) => {
              setDirectPaymentList(
                res?.data?.data.filter((x) => x.isActive === true)
              );
              setShowLoader(false);
              setSavedItem(0);
            },
            (err) => {
              console.log(err);
            }
          );          
        },
        (err) => {
          setShowLoader(false);
          console.log(err);
        }
      );
    } else {
      ServiceClient.service(ServiceType.DirectPaymentService).PUT(
        agencyData,
        (res) => {
          setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
          setShowLoader(false);
          handleReset();
          e.target.reset();
          if(agencyData.isActive== "true"){
            setSavedItem(agencyData.directPaymentId);

          }
           
        },
        (err) => {
          setShowLoader(false);
          console.log(err);
        }
      );
    }
  };

  return (
    <div className="card card-primary">
      <div className="card-header">
        <h3 className="card-title">Create Direct Payment</h3>
        <div className="card-tools"></div>
      </div>
      <div className="card-body">
        <form onSubmit={formSubmit}>
          <div className="row">
            <div className="col-md-6">

              <div className="form-group">
                <label>Customer Name</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={SelDirectPayment?.customer?.customerName}
                    name="customerName"
                    inputMode="text"
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Amount</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-credit-card"></i>
                    </span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={SelDirectPayment?.amount}
                    name="amount"
                    inputMode="text"
                    required
                  />
                </div>
              </div>




            </div>
            <div className="col-md-6">

              <div className="form-group">
                <label>Expiry Date</label>
                <div
                  className="input-group date"
                  id="reservationdatetime"
                  data-target-input="nearest"
                >
                  <DatePicker
                    name="expiry"
                    className="form-control"
                    dateFormat="dd-MMM-yyyy"
                    selected={SelDirectPayment != null ? new Date( SelDirectPayment?.expiry) : startExpDate}
                    onSelect={handleDateSelect} //when day is clicked
                  />

                </div>
              </div>
              <div className="form-group">
                <label>Currency</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-credit-card"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={SelDirectPayment != null ? SelDirectPayment.currencyCode : "KWD"}
                    name="currencyCode"
                    inputMode="text"
                    required
                    readOnly
                  />
                </div>
              </div>

              

              
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
            <div className="form-group clearfix">
                <label></label>
                <div className="icheck-primary d-inline">
                  <input
                    type="checkbox"
                    id="checkboxPrimary3"
                    disabled=""
                    checked={isChecked}
                    onChange={handleOnChange}
                    name="isActive"
                    value={isChecked}
                  />
                  <label htmlFor="checkboxPrimary3">IsActive</label>
                </div>
              </div>
              </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
            {!showLoader ? (
                <button type="submit" className="btn btn-primary btn-space">
                  {SelDirectPayment != null ? "Update" : "Submit"}
                </button>
              ) : (
                <button
                  className="btn btn-primary btn-space"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              )}

              <button
                type="reset"
                className="btn btn-danger btn-space"
                onClick={() => handleReset()}
              >
                Reset
              </button>
              </div>
          </div>
        </form>
        {message !== null &&
          message !== "" &&
          message === MessageType.Success ? (
          <div className="alert alert-success">New Agency Created</div>
        ) : message != null && message === MessageType.Fail ? (
          <div className="alert alert-danger">agency Creation Failed</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default CreateDirectPayment;
