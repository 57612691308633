import HttpClient from "../APIService/RestAPI";

const RoleServiceClient = {
  RoleList: getRoles,
  UserRolesList:getUserRoles,
  POST: postUserRoles,
//   PUT: putUserRoles
};

function getRoles(callback, errorcallback) {
  const URL = "api/master-records/roles";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.GET(URL, config, callback, errorcallback);
}

function getUserRoles(data, callback, errorcallback) {
    const URL = "api/userrole/manage-roles/user/" + data;
  
    var config = { "Access-Control-Allow-Origin": "*" };
  
    return HttpClient.GET(URL, config, callback, errorcallback);
  }

function postUserRoles(data, callback, errorcallback)  {
  const URL = `api/userrole/manage-roles`;

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.POST(URL, data, config, callback, errorcallback);
}

// function putUserRoles(data,callback, errorcallback) {
//   const URL = "api/user/manage-users";

//   var config = { "Access-Control-Allow-Origin": "*" };

//   return HttpClient.PUT(URL,data, config, callback, errorcallback);
// }

export default RoleServiceClient;
