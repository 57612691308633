function getItem(key) {
    return localStorage.getItem(key);
  }
  
  function setItem(key, data) {
    localStorage.setItem(key, data);
  }
  
  const LocalStore = {
    Get: getItem,
  
    Set: setItem,
  };
  
  export default LocalStore;