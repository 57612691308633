import React from "react";


const PageHeader = {
  Content: Content
};

function Content({Page}) {
  return (    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">{Page}</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item">
                Home
              </li>
              <li className="breadcrumb-item active">{Page}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}


export default PageHeader;