import React, { useEffect, useState } from "react";
import { AgencyVM } from "../../../Model/AgencyVM";
import ServiceClient from "../../../Services/ServiceFactory";
import { ServiceType } from "../../../Services/Util/ServiceType";
import { MessageType } from "../../../Services/Util/MessageType";

const AgencyCrud = {
  Content: Content,
};

function Content({
  SelAgency,
  setEditAgency,
  setAgencyList,
  setShowLoader,
  showLoader,
  deleteAgency,
  setDeleteAgency,
  isRefresh
}) {
  const [isChecked, setIsChecked] = useState(true);

  const [countries, setCountries] = useState([]);

  const [message, setMessage] = useState("");

  const [picture, setPicture] = useState(null);

  const [imgData, setImgData] = useState(null);

  const [selCountry, setselCountry] = useState("");

  const [mobileNo, setmobileNo] = useState("");

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  function handleCountryChange(event) {
    setselCountry(event.target.value);
  }

  function handleReset() {
    setEditAgency(null);
  }

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (SelAgency != null) {
      setIsChecked(SelAgency.isActive);
      window.scrollTo(0, 0);
      setselCountry(SelAgency.country);
    }
  }, [SelAgency]);

  useEffect(() => {
    ServiceClient.service(ServiceType.AgencyService).AgencyList(
      (res) => {
        setAgencyList(res?.data?.data.filter((x) => x.isActive === true));
        setShowLoader(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [message,isRefresh]);

  useEffect(() => {
    if (deleteAgency != null) {
      ServiceClient.service(ServiceType.AgencyService).DELETE(
        deleteAgency,
        (res) => {
          setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
        },
        (err) => {
          console.log(err);
        }
      );
      setDeleteAgency(null);
    }
  }, [deleteAgency]);

  const formSubmit = (e) => {
    e.preventDefault();

    let agencyData = new AgencyVM({
      agencyId: SelAgency != null ? SelAgency.agencyId : 0,
      agencyname: e.currentTarget.agencyName.value,
      email: e.currentTarget.agencyEmail.value,
      country: e.currentTarget.agencyCountry.value,
      address: e.currentTarget.agencyAddress.value,
      mobile: e.currentTarget.agencyPhone.value,
      logo: '',
      isActive: e.currentTarget.agencyIsActive.value,
    });
    if (SelAgency == null) {
      ServiceClient.service(ServiceType.AgencyService).POST(
        agencyData,
        (res) => {
          setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
          setShowLoader(false);
          setEditAgency(null);
          setselCountry();
          e.target.reset();
        },
        (err) => {
          setShowLoader(false);
          console.log(err);
        }
      );
    } else {
      ServiceClient.service(ServiceType.AgencyService).PUT(
        agencyData,
        (res) => {
          setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
          setShowLoader(false);
          setEditAgency(null);
          setselCountry();
          e.target.reset();
        },
        (err) => {
          setShowLoader(false);
          console.log(err);
        }
      );
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage("");
    }, 10);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    ServiceClient.service(ServiceType.MasterService).CountryList(
      (res) => {
        setCountries(res?.data.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  return (
    <div className="card card-primary">
      <div className="card-header">
        <h3 className="card-title">Create Agency</h3>
        <div className="card-tools"></div>
      </div>
      <div className="card-body">
        <form onSubmit={formSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Agency Name</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={SelAgency != null ? SelAgency.agencyName : ""}
                    name="agencyName"
                    inputMode="text"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Agency Email</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-envelope"></i>
                    </span>
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    defaultValue={SelAgency != null ? SelAgency.email : ""}
                    name="agencyEmail"
                    inputMode="text"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Agency Address</label>
                <textarea
                  type="textarea"
                  className="form-control"
                  defaultValue={SelAgency != null ? SelAgency.address : ""}
                  name="agencyAddress"
                  inputMode="text"
                  required
                ></textarea>
              </div>
              
              
            </div>
            <div className="col-md-6">
              <div className="form-group d-none">
                <label htmlFor="exampleInputFile">Agency Logo</label>
                <div className="input-group">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      name="agencyLogo"
                      id="exampleInputFile"
                      onChange={onChangePicture}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="exampleInputFile"
                    >
                      Choose file
                    </label>
                  </div>
                </div>
                {imgData !== null ? (
                  <img
                    alt="Logo"
                    style={{
                      width: "50px",
                      height: "50px",
                      float: "right",
                      textalign: "right",
                    }}
                    src={imgData}
                  />
                ) : (
                  ""
                )}
              </div>





              <div className="form-group">
                <label>Agency Mobile</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-phone"></i>
                    </span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={SelAgency != null ? SelAgency.mobile : ""}
                    name="agencyPhone"
                    inputMode="number"
                    required
                  />
                </div>
              </div>



              <div className="form-group">
                <label>Choose Country</label>
                <select
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  data-select2-id="1"
                  tabIndex="-1"
                  aria-hidden="true"
                  name="agencyCountry"
                  value={selCountry}
                  onChange={handleCountryChange}
                >
                  <option key={-1} defaultValue={-1}>
                    Select
                  </option>
                  {countries
                    .filter((x) => x.IsActive == true)
                    .map((obj) => (
                      <option
                        key={obj.CountryCode}
                        defaultValue={obj.CountryCode}
                      >
                        {obj.CountryName}
                      </option>
                    ))}
                </select>
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>
              
              

              


              {message !== null &&
                message !== "" &&
                message === MessageType.Success ? (
                <div className="alert alert-success">New Agency Created</div>
              ) : message != null && message === MessageType.Fail ? (
                <div className="alert alert-danger">agency Creation Failed</div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
            <div className="form-group clearfix">
                <label></label>
                <div className="icheck-primary d-inline">
                  <input
                    type="checkbox"
                    id="checkboxPrimary3"
                    disabled=""
                    checked={isChecked}
                    onChange={handleOnChange}
                    name="agencyIsActive"
                    value={isChecked}
                  />
                  <label htmlFor="checkboxPrimary3">IsActive</label>
                </div>
              </div>
              </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
            {!showLoader ? (
                <button type="submit" className="btn btn-primary btn-space">
                  {SelAgency != null ? "Update" : "Submit"}
                </button>
              ) : (
                <button
                  className="btn btn-primary btn-space"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              )}

              <button
                type="reset"
                className="btn btn-danger btn-space"
                onClick={() => handleReset()}
              >
                Reset
              </button>
              </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AgencyCrud;
