import AppConfig from "./AppConfig";

let dateFormat = (param, dateFormat) => {
  if(param != ''){
    const format = dateFormat ?? AppConfig.dateFormat;
    let date = new Date(param);

    switch (format) {
      case "dd-MMM-yyyy":
        date = date
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          })
          .split(" ")
          .join("-");
        break;
      case "dd-MM-yyyy":
        date = date
          .toLocaleDateString("en-GB", {
            day: "numeric",
            month: "2-digit",
            year: "2-digit",
          })
          .split(" ")
          .join("-");
        break;
      default:
        break;
    }
    return date;
  }
  return '';
};

let currencyFormat = (amount, currency) => {
  const format = AppConfig.currencyFormat;
  const formattedAmount =
    AppConfig.currencyOrder === "2"
      ? `${amount} ${format} ${currency}`
      : `${currency} ${format} ${amount}`;

  return formattedAmount;
};

export const Fomatter = {
  Dateformat: dateFormat,
  CurrencyFormat: currencyFormat,
};
