export class DirectPaymentVM {
  constructor({
    amount,
    currencyCode,
    remark,
    isActive,
    expiry,
    customer,
    directPaymentId,
    customerId,
    agencyId
  }) {
    this.amount = amount;
    this.currencyCode = currencyCode;
    this.remark = remark;
    this.isActive = isActive;
    this.expiry = expiry;
    this.directPaymentId = directPaymentId;
    this.customerId = customerId;
    this.agencyId = agencyId;
    this.customer = customer;
  }
}
export class CustomerVM {
  constructor({ CustomerId,saluation, customerName, email, phoneNumber, address }) {
    this.CustomerId=CustomerId;
    this.saluation = saluation;
    this.customerName = customerName;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.address = address;
  }
}
