import HttpClient from "../APIService/RestAPI";

const MasterServiceClient = {
  CountryList: getCountries,
  GateWayList: getGateWayList,
  Mobilecodes: getMobileCodes,
};

function getCountries(callback, errorcallback) {
  const URL = "api/master-records/country";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.GET(URL, config, callback, errorcallback);
}

function getMobileCodes(callback, errorcallback) {
  const URL = "api/master-records/mobile-codes";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.GET(URL, config, callback, errorcallback);
}

function getGateWayList(callback, errorcallback) {
  const URL = "api/master-records/payment-gateway";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.GET(URL, config, callback, errorcallback);
}

export default MasterServiceClient;
