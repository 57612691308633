import React, { useState, useEffect } from "react";
import Header from "./Components/Page/Master/Header";
import Sidebar from "./Components/Page/Master/Sidebar";
import Footer from "./Components/Page/Master/Footer";
import Content from "./Components/Page/Master/Content";
import { BrowserRouter } from "react-router-dom";
import PageHeader from "./Components/Page/Master/ContentPageHeader";
import User from "./Components/Page/Authentication/Login";
import AuthClient from "./Services/ServiceClient/AuthService";
import RenderScript from "./Components/Page/Master/HtmlHelmet";
import ServiceClient from "./Services/ServiceFactory";
import { ServiceType } from "./Services/Util/ServiceType";
import AppConfig from "./Services/Util/AppConfig";

function App() {
  const [page, setPage] = useState("dashboard");

  const [token, setToken] = useState();

  const [roles, setRoles] = useState([]);

  let loginDisplay = "block";

  let contentDisplay = "none";
  function CheckAuth() {
    useEffect(() => {
      var user = AuthClient.User();
      if (user !== null) {
        setToken(user);
        setInterval(RefreshToken, AppConfig.refreshInterval*1000*60);  
        var roles = AuthClient.getRole();
        setRoles(roles?.split(','));
      }
    }, []);

    if (!token) {
      loginDisplay = "block";
      contentDisplay = "none";
    } else {
      loginDisplay = "none";
      contentDisplay = "block";
    }
  }
  CheckAuth();

  function RefreshToken()
  {
    ServiceClient.service(ServiceType.RefreshToken).RefreshToken(
      new Object({
        Token: AuthClient.User()
      }),
      (res) => {
        AuthClient.setUser(res.data?.token);
        setToken(res.data?.token);
      },
      (err) => {
        console.log('error');
      }
    );
  }

  return (
    <>
      <BrowserRouter>
      <div name="login" style={{display:loginDisplay}}>
            <User.Login setToken={setToken}setRoles={setRoles}  token={token}/>;
      </div>
      <div style={{display:contentDisplay}}>
            <RenderScript />
            <div name="content">
              <Header.Content selPage={setPage} />
              <div className="content-wrapper">
                <PageHeader.Content Page={page} setPage={setPage} />
                <section className="content">
                  <Content token={token}selPage={setPage}/>
                </section>
              </div>
              <Sidebar.Content roles={roles}/>
              <Footer.Content />
            </div>
          </div>        
      </BrowserRouter>
    </>
  );
}
//const LazyCon = React.lazy(()=>import('./Components/Page/Master/ContentLazy'));

export default App;
