import React from "react";
import { Routes, Route } from "react-router-dom";
import AgencyDashBoard from "../../AgencyDashBoard";
import Agency from "../AgencyManagement/Agency";
import { RouteConfig } from "../../../routes";
import UserManage from "../UserManagement/User";
import UserManager from "../UserManagement/UserAssignRole";
import ServiceChargeManage from '../ServiceChargeManagement/ServiceCharge';
import DirectPayment from "../DirectPayment/DirectPayment";
import DirectPayments from "../ReportManagement/DirectPayments";
import PaymentTransactions from "../ReportManagement/PaymentTransactions";

const Content = ({ token,selPage }) => (
  <Routes>
    <Route
      exact
      path={RouteConfig.Presentation.path}
      element={<AgencyDashBoard token={token} selPage={selPage}/>}
    />
    <Route exact path={RouteConfig.Agency.path} element={<Agency selPage={selPage}/>} />
    <Route
      exact
      path={RouteConfig.UserCreate.path}
      element={<UserManage.Create.Content selPage={selPage}/>}
    />
    <Route
      exact
      path={RouteConfig.UserAssignRole.path}
      element={<UserManager.AssignRole.Content selPage={selPage}/>}
    />
    <Route
      exact
      path={RouteConfig.DashboardOverview.path}
      element={<AgencyDashBoard token={token}selPage={selPage}/>}
    />
    <Route
      exact
      path={RouteConfig.GateWayCharge.path}
      element={<ServiceChargeManage.Content selPage={selPage}/>}
    />
     <Route
      exact
      path={RouteConfig.DirectpaymentCreate.path}
      element={<DirectPayment selPage={selPage}/>}/>
    <Route
      exact
      path={RouteConfig.Report.DPay}
      element={<DirectPayments selPage={selPage}/>}
    />
    <Route
      exact
      path={RouteConfig.Report.DTran}
      element={<PaymentTransactions selPage={selPage}/>}
    />
    <Route to={RouteConfig.NotFound.path} />
  </Routes>
);

export default Content;
