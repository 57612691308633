import React, { useState,useEffect } from "react";
import UserCrud from "./UserCrud";
import UserView from "./UserView";

const UserManage = {
  Create: {
    Content: Content,
  },
};

function Content({ selPage }) {
  
  const [editUser, setEditUser] = useState();
  const [deleteUser, setDeleteUser] = useState();
  const [userList, setUserList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const[isRefresh,setIsRefresh]=useState(false);

  useEffect(() => {
    selPage("User Management");
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <UserCrud.Create.Content
              SelUser={editUser}
              setEditUser={setEditUser}
              setUserList={setUserList}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
              deleteUser={deleteUser}
              setDeleteUser={setDeleteUser}
              isRefresh={isRefresh}
              setIsRefresh={setIsRefresh}
            />
            <UserView.Create.Content
              setEditUser={setEditUser}
              userList={userList}
              setShowLoader={setShowLoader}
              showLoader={showLoader}
              setDeleteUser={setDeleteUser}
              isRefresh={isRefresh}
              setIsRefresh={setIsRefresh}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default UserManage;
