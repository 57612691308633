import HttpClient from "../APIService/RestAPI";

const LoginServiceClient = {
  Login: Login,
  RefreshToken:RefreshToken
};

function Login(data, callback, errorcallback) {
  const loginURL = "/token";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.POST(loginURL, data, config, callback, errorcallback);
}

function RefreshToken(data, callback, errorcallback) {
  
  const URL = "/refreshToken";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.POST(URL, data, config, callback, errorcallback);
}

export default LoginServiceClient;
