import HttpClient from "../APIService/RestAPI";

const AgencyServiceClient = {
  AgencyList: getAgencies,
  POST: postAgencies,
  PUT: putAgencies,
  DELETE: deleteAgencies,
};

function getAgencies(callback, errorcallback) {
  const agencyURL = "api/agency/manage-agencies";

  var config = { "Access-Control-Allow-Origin": "*" };  

  return HttpClient.GET(agencyURL, config, callback, errorcallback);
}

function postAgencies(data, callback, errorcallback) {
  const agencyURL = `api/agency/manage-agencies`;

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.POST(agencyURL, data, config, callback, errorcallback);
}

function putAgencies(data,callback, errorcallback) {
  const agencyURL = "api/agency/manage-agencies";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.PUT(agencyURL,data, config, callback, errorcallback);
}

function deleteAgencies(data,callback, errorcallback) {
  const agencyURL = "api/agency/manage-agencies/"+data;

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.DELETE(agencyURL, config, callback, errorcallback);
}

export default AgencyServiceClient;
