import React, { useState,useEffect } from "react";
import PaymentView from "./DirectPaymentView";
import PaymentCreate from "./CreateDirectPayment";

function DirectPayment({ selPage }) {

  const DateFromUTC = (custDate) => {
    return new Date(Date.UTC(custDate.getFullYear(), custDate.getMonth(), custDate.getDate()));
  }
  
  const [idEditedDPay, setIdEditedDPay] = useState(0);
  const [editPayment, setEditDirectPayment] = useState();
  const [PaymentList, setDirectPaymentList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [deletePayment, setDeleteDirectPayment] = useState();
  const [agencyList, setAgencyList] = useState([]);
  const [savedItem, setSavedItem] = useState();
  const[isRefresh,setIsRefresh]=useState(false);
  const [userList, setUserList] = useState([]);
  const [toDate, setToDate] = useState(DateFromUTC(new Date()));
  const [fromDate, setFromDate] = useState(DateFromUTC(new Date(toDate.getFullYear(), toDate.getMonth(), 1)));
  

  useEffect(() => {
    selPage("Direct Payment");
   
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <PaymentCreate.Content
              SelDirectPayment={editPayment}
              setEditDirectPayment={setEditDirectPayment}
              setDirectPaymentList={setDirectPaymentList}
              setShowLoader={setShowLoader}
              showLoader={showLoader}
              deleteDirectPayment={deletePayment}
              setDeleteDirectPayment={setDeleteDirectPayment}
              setAgencyList={setAgencyList}
              setIdEditedDPay={setIdEditedDPay}
              idEditedDPay={idEditedDPay}
              setSavedItem={setSavedItem}
              isRefresh={isRefresh}
              setUserList={setUserList}
              fromDate={fromDate}
              toDate={toDate}
            />
            <PaymentView.Content
            setEditDirectPayment={setEditDirectPayment}
            directPaymentList={PaymentList}
            setShowLoader={setShowLoader}
            showLoader={showLoader}
            setDeleteDirectPayment={setDeleteDirectPayment}
            agencyList={agencyList}
            setIdEditedDPay={setIdEditedDPay}
            savedItem={savedItem}
            setSavedItem={setSavedItem}
            isRefresh={isRefresh}
            setIsRefresh={setIsRefresh}
            userList={userList}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DirectPayment;