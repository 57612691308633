import React, { useState, useEffect } from "react";
import "../DirectPayment/Paginate.css";
import ReactPaginate from 'react-paginate';
import "../../../TableStyle.css";

const UserView = {
  Create: {
    Content: Content,
  },
};


function Content({ 
  setEditUser,
  userList,
  setShowLoader,
  showLoader,
  setDeleteUser,
  isRefresh,
  setIsRefresh
 }) {

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  function refresh() {
    setShowLoader(true);
    setIsRefresh(!isRefresh);
  }

  function deleteUser(id) {
    const confirmBox = window.confirm(
      "Do you really want to delete this User?"
    )
    if (confirmBox === true) {
      setDeleteUser(id);       
    }
   
  }

  useEffect(() => {
    const itemsPerPage  = 10;
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(userList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(userList.length / itemsPerPage));
  }, [itemOffset, userList]);

  const handlePageClick = (event) => {
    const itemsPerPage  = 10;
    const newOffset = (event.selected * itemsPerPage) % userList.length;
    setItemOffset(newOffset);
  };

 
    return (
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">User List</h3>&nbsp;&nbsp;&nbsp;
          <i className="fa fa-sync-alt" title="Refresh"
          onClick={() => refresh()}
        ></i>
        </div>
  
        <div className="card-body">
          {showLoader ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : userList.length > 0 ? (

            <div className="table-responsive">
              <table className="table text-center">
        <thead>
          <tr className="t-head-bg text-light">
            <th scope="col">#</th>
            <th scope="col">UserName</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Edit</th>
            <th scope="col">Remove</th>
          </tr>
        </thead>
        <tbody className="tbody_strip">
		{currentItems.map((item, index) => (
          <tr key={index}>
            <td>{item.userId}</td>
            <td>{item.userName }</td>
            <td>{item.firstName}{item.middleName}{item.lastName}</td>
            <td>{item.email}</td>
            <td>{ <i
                      style={{ cursor: "pointer" }}
                      className="fa fa-edit"
                      aria-hidden="true"
                      onClick={() => setEditUser(item)}
                    ></i> }</td>
            <td>{ 
                    <i
                    style={{ cursor: "pointer" }}
                    className="fa fa-minus-circle"
                    aria-hidden="true"
                    onClick={() => deleteUser(item.userId)}
                  ></i>
                  }</td> 
          </tr>    
     ))}		  
        </tbody>
      </table>
          </div>
          ) : (
            <div className="alert alert-danger"></div>
          )}
        </div>
  
        <div className="div-paginate">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">>"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<<"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>)
}


export default UserView;