import React, { useState,useEffect } from "react";
import AgencyView from "./AgencyView";
import AgencyCrud from "./AgencyCrud";

function Agency({ selPage }) {
  
  const [editAgency, setEditAgency] = useState();
  const [agencyList, setAgencyList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [deleteAgency, setDeleteAgency] = useState();
  const[isRefresh,setIsRefresh]=useState(false);

  useEffect(() => {
    selPage("Agency Management");
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <AgencyCrud.Content
              SelAgency={editAgency}
              setEditAgency={setEditAgency}
              setAgencyList={setAgencyList}
              setShowLoader={setShowLoader}
              showLoader={showLoader}
              deleteAgency={deleteAgency}
              setDeleteAgency={setDeleteAgency}
              isRefresh={isRefresh}
            />
            <AgencyView.Content
            setEditAgency={setEditAgency}
            agencyList={agencyList}
            setShowLoader={setShowLoader}
            showLoader={showLoader}
            setDeleteAgency={setDeleteAgency}
            isRefresh={isRefresh}
            setIsRefresh={setIsRefresh}
             />
          </div>
        </div>
      </div>
    </>
  );
}

export default Agency;
