import React, { useState, useEffect } from "react";
import "../DirectPayment/Paginate.css";
import ReactPaginate from 'react-paginate';
import "../../../TableStyle.css";

const ServiceChargeView = {
  Create: {
    Content: Content,
  },
};


function Content({
   setEditCharge,
   gateWayList,
   ChargeList,
   setShowLoader,
   showLoader,
   setDeleteCharge,
   agencyList,
   isRefresh,
   setIsRefresh
   }) {

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
  
  function deleteSettings(id) {
    const confirmBox = window.confirm(
      "Do you really want to delete this Settings?"
    )
    if (confirmBox === true) {
        setDeleteCharge(id);       
    }
   
  }

  function refresh() {
    setShowLoader(true);
    setIsRefresh(!isRefresh);
  }

  useEffect(() => {
    const itemsPerPage  = 10;
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(ChargeList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(ChargeList.length / itemsPerPage));
  }, [itemOffset, ChargeList]);

  const handlePageClick = (event) => {
    const itemsPerPage  = 10;
    const newOffset = (event.selected * itemsPerPage) % ChargeList.length;
    setItemOffset(newOffset);
  };

 
    return (
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Service Charge List</h3>&nbsp;&nbsp;&nbsp;
          <i className="fa fa-sync-alt" title="Refresh"
          onClick={() => refresh()}
        ></i>
        </div>
  
        <div className="card-body">
          {showLoader ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : ChargeList.length > 0 ? (

            <div className="table-responsive">
              <table className="table text-center">
        <thead>
          <tr className="t-head-bg text-light">
            <th scope="col">#</th>
            <th scope="col">Agency</th>
            <th scope="col">Gateway</th>
            <th scope="col">Amount</th>
            <th scope="col">Type</th>
            <th scope="col">Edit</th>
			<th scope="col">Remove</th>
          </tr>
        </thead>
        <tbody className="tbody_strip">
		  {currentItems.map((item, index) => (
          <tr key={index}>
            <td>{item.serviceChargeId}</td>
            <td>{agencyList!=''?agencyList.find(x => x.agencyId === item.agencyId)?.agencyName:item.agencyId }</td>
            <td>{gateWayList!=''?gateWayList.find(x => x.PaymentGatewayId === item.paymentGatewayId)?.PaymentGatewayName:item.paymentGatewayId}</td>
            <td>{item.amount}</td>
			<td>{item.isPercentage===true?'Percentage':'Amount'}</td>
            <td>{ <i
                    style={{ cursor: "pointer" }}
                    className="fa fa-edit"
                    aria-hidden="true"
                    onClick={() => setEditCharge(item)}
                  ></i> }</td>
            <td>{ 
                  <i
                  style={{ cursor: "pointer" }}
                  className="fa fa-minus-circle"
                  aria-hidden="true"
                  onClick={() => deleteSettings(item.serviceChargeId)}
                ></i>
                }</td> 
          </tr>    
     ))}		  
        </tbody>
      </table>
           </div>
          ) : (
            <div className="alert alert-danger"></div>
          )}
        </div>
  
        <div className="div-paginate">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<<"
          renderOnZeroPageCount={null}
        />
      </div>
      </div>)
}


export default ServiceChargeView;