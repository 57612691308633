import React, { useState,useEffect } from "react";
import ServiceChargeCrud from './ServiceChargeCrud';
import ServiceChargeView from './ServiceChargeView';

const ServiceChargeManage = {
  Content: Content,
};

function Content({ selPage }) {
 
  const [editCharge, setEditCharge] = useState();
  const [deleteCharge, setDeleteCharge] = useState();
  const [ChargeList, setChargeList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [gateWayList, setgateWayList] = useState([]);  
  const [agencyList, setAgencyList] = useState([]);
  const[isRefresh,setIsRefresh]=useState(false);

  useEffect(() => {
    selPage("GateWay Charge Settings");
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <ServiceChargeCrud.Create.Content
              SelCharge={editCharge}
              setEditCharge={setEditCharge}
              setChargeList={setChargeList}
              ChargeList={ChargeList}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
              deleteCharge={deleteCharge}
              setDeleteCharge={setDeleteCharge}
              setgateWayList={setgateWayList}
              gateWayList={gateWayList}
              agencyList={agencyList}
              setAgencyList={setAgencyList}
              isRefresh={isRefresh}
            />
            <ServiceChargeView.Create.Content
              setEditCharge={setEditCharge}
              gateWayList={gateWayList}
              ChargeList={ChargeList}
              setShowLoader={setShowLoader}
              showLoader={showLoader}
              setDeleteCharge={setDeleteCharge}           
              agencyList={agencyList}   
              isRefresh={isRefresh}
              setIsRefresh={setIsRefresh}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceChargeManage;
