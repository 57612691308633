import React, { useEffect, useState, useRef } from "react";
import ServiceClient from "../../../Services/ServiceFactory";
import { ServiceType } from "../../../Services/Util/ServiceType";
import { MessageType } from "../../../Services/Util/MessageType";
import $ from "jquery";
import { Fomatter } from "../../../Services/Util/Formatter";

const CopyLinkView = {
  Content: Content,
};

function Content({
  showCopyLinkModel,
  setShowCopyLinkModel,
  selDirectPayment,
}) {
  const [message, setMessage] = useState("");
  const [payAmount, setpayAmount] = useState("");
  const [payCurrency, setpayCurrency] = useState("");
  const [payByDate, setpayByDate] = useState("");
  const [payAddress, setpayAddress] = useState("");
  const [dPayCopyContent, setDPayCopyContent] = useState({ info: {} });
  const [shareDirectPayment, setShareDirectPayment] = useState({});
  const [payCulture, setpayCulture] = useState("");

  const hideModal = () => {
    setShowCopyLinkModel(false);
    window.$(".modal.fade").modal("hide");
  };

  function handleCultureChange(event) {
    setpayCulture(event.target.value);
  }

  const buildWAppShare = () => {
    var lineBreak = "%0a";
    var linkUrl = ""; //AppConfig.paymentURL + https://link.triotrip.com/payments/xxxxxxx/direct-payment
    linkUrl += "Invoice from " + dPayCopyContent.agency.agencyName + '' + lineBreak;
    linkUrl +=
      "Amount: " +
      dPayCopyContent.info.currencyCode +
      " " +
      dPayCopyContent.info.amount +
      lineBreak;
      linkUrl +=
      "Click to Pay" +
      lineBreak;
      if(payCulture=="en"){
        linkUrl += encodeURI(dPayCopyContent.link + "?culture=en") + " (English)" + lineBreak;
      }
      else{
        linkUrl += encodeURI(dPayCopyContent.link + "?culture=ar") + " (Arabic)" + lineBreak;
      }

    window.open("https://api.whatsapp.com/send?text=" + linkUrl + "");
  };
  const buildEmailShare = (elm) => {
    setShareDirectPayment({
      id: selDirectPayment,
      address: payAddress,
      medium: 1      
    });
    ServiceClient.service(ServiceType.DirectPaymentService).ShareDPLink(
      {
        id: selDirectPayment,
        address: payAddress,
        medium: 1,
        culture:payCulture
      },
      (res) => {
        setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
        hideModal();
      },
      (err) => {
        console.log(err);
      }
    );
  };

  function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }
}

  useEffect(() => {
    if (showCopyLinkModel != null && showCopyLinkModel === true) {
      ServiceClient.service(
        ServiceType.DirectPaymentService
      ).DirectPaymentCopyLink(
        selDirectPayment+"/en",
        (res) => {
          var respPG = res?.data?.data;
          setpayAmount(respPG.info.amount);
          setpayCurrency(respPG.info.currencyCode);
          setpayByDate(respPG.info.expiry);
          setDPayCopyContent(respPG);
          setpayCulture("en");
          window.$(".modal.fade").modal("show");
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [showCopyLinkModel]);

  return (
    <div
      className="modal fade bd-example-modal-lg"
      role="dialog"
      aria-labelledby="gridSystemModalLabel"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          {/* <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div> */}
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12 share-container">
                <h5>
                  {" "}
                  Amount :
                  <span className="badge badge-pill badge-success">{`${payCurrency} ${payAmount}`}</span>
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 share-container">
                <h5>
                  Expires :
                  <span className="badge badge-pill badge-dark">
                    {Fomatter.Dateformat(payByDate)}
                  </span>
                </h5>
              </div>
            </div>

            <div className="row share-container">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">                    
                    <select
                      className="form-control select2 select2-hidden-accessible"
                      style={{ width: "100%" }}
                      data-select2-id="1"
                      tabIndex="-1"
                      aria-hidden="true"
                      name="culture"
                      value={payCulture}
                      onChange={handleCultureChange}
                    >
                      <option key={0} defaultValue={0} value="en">
                        English
                      </option>
                      <option key={1} value="ar">
                        Arabic
                      </option>                      
                </select>

                  </div>                
                </div>
              </div>
              
            </div>

                    
            <div className="row">
            <div className="col-md-12">
                <div className="input_copy_wrapper">

                    {(() => {
            if (payCulture=="en") {
              return (
                <div className="input_copy">
                  <span className="txt">English link</span>
                    <span className="txt">
                      {dPayCopyContent.link + "?culture=en"}
                    </span>
                    <span
                      className="icon right"
                      onClick={() => {copyToClipboard(dPayCopyContent.link + "?culture=en")}}
                    >
                      <img
                        src="/assets/img/copy_ico.png"
                        title="Click to Copy"
                        alt="image_ico"
                      />
                    </span>
                  </div>
              )
            } else {
              return (
                <div className="input_copy">
                  <span className="txt">Arabic link</span>
                    <span className="txt">
                      {dPayCopyContent.link + "?culture=ar"}
                    </span>
                    <span
                      className="icon right"
                      onClick={() => {copyToClipboard(dPayCopyContent.link + "?culture=ar")}}
                    >
                      <img
                        src="/assets/img/copy_ico.png"
                        title="Click to Copy"
                        alt="image_ico"
                      />
                    </span>
                  </div>
              )
            }
          })()}

                </div>
              </div>
            </div>
            <div className="row share-container">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email Address"
                      onChange={(e) => setpayAddress(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <button
                      onClick={() => buildEmailShare(this)}
                      className="btn btn-primary btn-block mail-share"
                    >
                      <i className="fas fa-envelope"></i>Share
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <img
                  className="whtsapp-share"
                  alt=""
                  onClick={() => buildWAppShare()}
                  data-action="share/whatsapp/share"
                  src="/assets/img/whatsapp_ico.svg"
                  title="Click to Copy"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => hideModal()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CopyLinkView;
