function getItem(key) {
  return sessionStorage.getItem(key);
}

function setItem(key, data) {
  sessionStorage.setItem(key, data);
}

const SessionStore = {
  Get: getItem,

  Set: setItem,
};

export default SessionStore;
