import React from "react";
import { Link } from "react-router-dom";
import { RouteConfig } from "../../../routes";
import AuthClient from "../../../Services/ServiceClient/AuthService";
import {isMobile} from 'react-device-detect';

const Sidebar = {
  Content: Content,
};

function Content({roles}) {
 
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="./assets/img/User-image.png"
              className="img-circle elevation-2"
              alt="UserImage"
            />
          </div>
          <div className="info" style={{fontSize:"30px", fontWeight:"bold"}}>
            <a href="/#" className="d-block">
              {AuthClient.Name()}
            </a>
          </div>
        </div>
        <div className="form-inline">
          <div className="input-group" data-widget="sidebar-search">
            <input
              className="form-control form-control-sidebar"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw"></i>
              </button>
            </div>
          </div>
        </div>

        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className={isMobile?"nav-item menu-is-opening menu-open":"nav-item"}>
              <a href="#/" className="nav-link active">
                <i className="nav-icon fas fa-user-alt"></i>
                <p>
                  Management
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">

              {
              roles.includes('ADMINISTRATOR') ? (
                  <li className="nav-item">
                  <Link
                    to={RouteConfig.Agency.path}
                    className="nav-link active"
                    key="hmk"
                  >
                    <i className="far fa-circle nav-icon"></i>
                    <p>Agency</p>
                  </Link>
                </li>
                ) : (
                  <></>
                 )}

              {
              roles.includes('ADMINISTRATOR') || roles.includes('AGENCYADMINISTRATOR') ? (

                <li className={isMobile?"nav-item menu-is-opening menu-open":"nav-item"}>
                  <Link
                    to="javascript.void(0)"
                    className="nav-link"
                    key="hmk"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <i className="far fa-circle nav-icon"></i>
                    <p>
                      User
                      <i className="fas fa-angle-left right"></i>
                    </p>
                  </Link>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        to={RouteConfig.UserCreate.path}
                        className="nav-link"
                        key="hmk"
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Create</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={RouteConfig.UserAssignRole.path}
                        className="nav-link"
                        key="hmk"
                      >
                        <i className="far fa-circle nav-icon"></i>
                        <p>Assign Roles</p>
                      </Link>
                    </li>
                  </ul>
                </li>) : (
                  <></>
                 )}

              {
              roles.includes('ADMINISTRATOR') ? (
                <li className={isMobile?"nav-item menu-is-opening menu-open":"nav-item"}>
                  <Link
                    to={RouteConfig.GateWayCharge.path}
                    className="nav-link"
                    key="hmk"
                  >
                    <i className="far fa-circle nav-icon"></i>
                    <p>Service charge</p>
                  </Link>
                </li>
              ): (
                <></>
               )}
              </ul>
            </li>
            <li className="nav-item">
               <Link
                    to={RouteConfig.DirectpaymentCreate.path}
                    className="nav-link"
                    key="hmk"
                  >
                    <i className="nav-icon fas fa-credit-card"></i>
                    <p>Direct Payment</p>
                  </Link>
            </li>

            {/* <li className="nav-item">
              <a href="#/" className="nav-link">
                <i className="nav-icon fas fa-credit-card"></i>
                <p>
                  Direct Payment
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to={RouteConfig.DirectpaymentCreate.path}
                    className="nav-link"
                    key="hmk"
                  >
                    <i className="far fa-circle nav-icon"></i>
                    <p>Create</p>
                  </Link>
                </li>
                <li className="nav-item d-none">
                  <Link
                    to={RouteConfig.Agency.path}
                    className="nav-link"
                    key="hmk"
                  >
                    <i className="far fa-circle nav-icon"></i>
                    <p>Manage</p>
                  </Link>
                </li>
              </ul>
            </li> */}
            <li className={isMobile?"nav-item menu-is-opening menu-open":"nav-item"}>
              <a href="#/" className="nav-link">
                <i className="nav-icon fas fa-table"></i>
                <p>
                  Reports
                  <i className="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to={RouteConfig.Report.DPay}
                    className="nav-link"
                    key="hmk"
                  >
                    <i className="far fa-circle nav-icon"></i>
                    <p>Direct Payments</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={RouteConfig.Report.DTran}
                    className="nav-link"
                    key="hmk"
                  >
                    <i className="far fa-circle nav-icon"></i>
                    <p>PG Summary</p>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}
export default Sidebar;
