import React, { useState, useEffect } from "react";
import "../DirectPayment/Paginate.css";
import ReactPaginate from 'react-paginate';
import "../../../TableStyle.css";

const AgencyView = {
  Content: Content,
};



function Content({ 
  setEditAgency,
  agencyList,
  setShowLoader,
  showLoader,
  setDeleteAgency,
  isRefresh,
  setIsRefresh
}) {

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  function deleteAgency(id) {
    const confirmBox = window.confirm(
      "Do you really want to delete this Agency?"
    )
    if (confirmBox === true) {
      setDeleteAgency(id);   
    }
   
  } 

  function refresh() {
    setShowLoader(true);
    setIsRefresh(!isRefresh);
  } 

  useEffect(() => {
    const itemsPerPage  = 10;
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(agencyList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(agencyList.length / itemsPerPage));
  }, [itemOffset, agencyList]);

  const handlePageClick = (event) => {
    const itemsPerPage  = 10;
    const newOffset = (event.selected * itemsPerPage) % agencyList.length;
    setItemOffset(newOffset);
  };


    return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Agency List</h3>&nbsp;&nbsp;&nbsp;
        <i className="fa fa-sync-alt" title="Refresh"
          onClick={() => refresh()}
        ></i>
      </div>

      <div className="card-body">
        {showLoader ? (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : currentItems.length > 0 ? (

          <div className="table-responsive">
             <table className="table text-center">
        <thead>
          <tr className="t-head-bg text-light">
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Mobile</th>
            <th scope="col">Country</th>
            <th scope="col">Edit</th>
            <th scope="col">Remove</th>
          </tr>
        </thead>
        <tbody class="tbody_strip">
		{currentItems.map((item, index) => (
          <tr key={index}>
            <td>{item.agencyId}</td>
            <td>{item.agencyName }</td>
            <td>{item.mobile}</td>
            <td>{ item.country === "" || item.country === null ? "Nill" : item.country}</td>
            <td>{ <i
                      style={{ cursor: "pointer" }}
                      className="fa fa-edit"
                      aria-hidden="true"
                      onClick={() => setEditAgency(item)}
                    ></i> }</td>
            <td>{ 
                    <i
                    style={{ cursor: "pointer" }}
                    className="fa fa-minus-circle"
                    aria-hidden="true"
                    onClick={() => deleteAgency(item.agencyId)}
                  ></i>
                  }</td> 
          </tr>    
     ))}		  
        </tbody>
      </table>
          </div>
        ) : (
          <div className="alert alert-danger"></div>
        )}
      </div>

      <div className="div-paginate">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<<"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}

export default AgencyView;
