function getItem(key) {
  let name = key + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setItem(key, data, exdays) {
  const d = new Date();
  exdays = exdays ?? 1;
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = key + "=" + data + ";" + expires + ";path=/";
}

const CookieStore = {
  Get: getItem,

  Set: setItem,
};

export default CookieStore;
