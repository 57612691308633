import React, { useEffect, useState } from "react";
import ServiceClient from "../../../Services/ServiceFactory";
import { ServiceType } from "../../../Services/Util/ServiceType";
import { GateWayChargeVM } from "../../../Model/GateWaychargeVM";
import { MessageType } from "../../../Services/Util/MessageType";

const ServiceChargeCrud = {
  Create: {
    Content: Content,
  },
};

function Content({
  SelCharge,
  setEditCharge,
  setChargeList,
  ChargeList,
  showLoader,
  setShowLoader,
  deleteCharge,
  setDeleteCharge,
  setgateWayList,
  gateWayList,
  agencyList,
  setAgencyList,
  isRefresh
}) {
  
  const [message, setMessage] = useState("");
  const [selAgency, setselAgency] = useState("");
  const [selGateWay, setSelGateWay] = useState("");
  const [selCurrency, setSelCurrency] = useState("");
  const [IsPercentage, setIsPercentage] = useState(1);
  
  
  function handleAgencychange(event) {
    setselAgency(event.target.value);
  }
  function handleGateWaychange(event) {
    setSelGateWay(event.target.value);
  }
  function handleCurrencychange(event) {
    setSelCurrency(event.target.value);
  }

  function onChangeValue(event) {
    setIsPercentage(event.target.value);
  } 
  function handleReset() {
    setEditCharge(null);
  }

  const currencyCode = [
    { value: "KWD", label: "KWD" }
  ]; 

  useEffect(() => {
    if (SelCharge != null) {
      setselAgency(SelCharge.agencyId);
      setSelGateWay(SelCharge.paymentGatewayId);
      setSelCurrency(SelCharge.currencyCode);
      setIsPercentage(SelCharge.isPercentage);
      window.scrollTo(0, 0);
    }
  }, [SelCharge]);

  useEffect(() => {
    ServiceClient.service(ServiceType.AgencyService).AgencyList(
      (res) => {
        setAgencyList(res?.data?.data.filter(x=>x.isActive==true));
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    if (deleteCharge != null) {
      ServiceClient.service(ServiceType.GatewayService).DELETE(
        deleteCharge,
        (res) => {
          setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
        },
        (err) => {
          console.log(err);
        }
      );
      setDeleteCharge(null);
    }
  }, [deleteCharge]);

  useEffect(() => {
    ServiceClient.service(ServiceType.MasterService).GateWayList(
      (res) => {
        setgateWayList(res?.data?.data);
      },
      (err) => {
        console.log(err);
      }
    );

    ServiceClient.service(ServiceType.GatewayService).ChargeList(
      (res) => {
        setChargeList(res?.data?.data.filter(x=>x.isActive==true));        
        setShowLoader(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [ChargeList, message,isRefresh]);

 

  const formSubmit = (e) => {
    e.preventDefault();  
    let ChargeData = new GateWayChargeVM({
      serviceChargeId:SelCharge != null ? SelCharge.serviceChargeId : 0,
      agencyId: selAgency,
      paymentGatewayId: selGateWay,
      amount: e.currentTarget.amount.value,
      currencyCode: selCurrency,
      isPercentage: IsPercentage?true:false,
      isActive: true
    });

    if (SelCharge == null)
    {
        ServiceClient.service(ServiceType.GatewayService).POST(
            ChargeData,
          (res) => {
            setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
            setShowLoader(false);
            e.target.reset();
            setEditCharge(null); 
            setChargeList([]);   
            setselAgency(-1);
            setSelGateWay(-1);
            setSelCurrency(-1);
            setIsPercentage(1);
          },
          (err) => {
            setShowLoader(false);
            console.log(err);
          }
        );
    }
    else
    {
      ServiceClient.service(ServiceType.GatewayService).PUT(
        ChargeData,
        (res) => {
          setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
          setShowLoader(false);
          e.target.reset();
          setEditCharge(null);    
          setChargeList([]);         
          setselAgency(-1);
          setSelGateWay(-1);
          setSelCurrency(-1);
          setIsPercentage(1);
        },
        (err) => {
          setShowLoader(false);
          console.log(err);
        }
      );
    }
  };

  return (
    <div className="card card-primary">
      <div className="card-header">
        <h3 className="card-title">Payment GateWay Charge Settings</h3>
        <div className="card-tools"></div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <form onSubmit={formSubmit}>

            <div className="form-group">
                <label>Agency</label>
                <select
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  data-select2-id="1"
                  tabIndex="-1"
                  aria-hidden="true"
                  value={selAgency}
                  name="agencyId"
                  onChange={handleAgencychange}
                >
                  <option key={-1} defaultValue={-1} value={-1}>
                    Select
                  </option>
                  {agencyList.map((obj) => (
                    <option
                      value={obj.agencyId}
                      key={obj.agencyId}
                      defaultValue={obj.agencyId}
                    >
                      {obj.agencyName}
                    </option>
                  ))}
                </select>
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>

              <div className="form-group">
                <label>Payment GateWay</label>
                <select
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  data-select2-id="1"
                  tabIndex="-1"
                  aria-hidden="true"
                  value={selGateWay}
                  name="gateWayId"
                  onChange={handleGateWaychange}
                >
                  <option key={-1} defaultValue={-1} value={-1}>
                    Select
                  </option>
                  {gateWayList.filter(x=>x.IsActive==true).map((obj) => (
                    <option
                      value={obj.PaymentGatewayId}
                      key={obj.PaymentGatewayId}
                      defaultValue={obj.PaymentGatewayId}
                    >
                      {obj.PaymentGatewayName}
                    </option>
                  ))}
                </select>
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>

              <div className="form-group">
                <label>Currency</label>
                <select
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  data-select2-id="1"
                  tabIndex="-1"
                  aria-hidden="true"
                  value={selCurrency}
                  name="currencyId"
                  onChange={handleCurrencychange}
                >
                  <option key={-1} defaultValue={-1} value={-1}>
                    Select
                  </option>
                  {currencyCode.map((obj) => (
                    <option
                      value={obj.value}
                      key={obj.value}
                      defaultValue={obj.value}
                    >
                      {obj.label}
                    </option>
                  ))}
                </select>
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>
              
              <div className="form-group">
                <label>Amount</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                    <i className="fas fa-server"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={SelCharge != null ? SelCharge.amount : ""}
                    name="amount"
                    inputMode="number"
                    required
                  />
                </div>
              </div>

              <div className="form-group clearfix">
              <div >
                <input
                    type="radio"
                    id="IsPercentage"
                    value="percentage"
                    name="IsPercentage"
                    checked={IsPercentage==1}        
                    onChange={() => setIsPercentage(1)}            
                  />
                  <label htmlFor="checkboxPrimary3" onClick={()=>setIsPercentage(1)}>Percentage</label>
                  &nbsp;                
                  <input
                    type="radio"
                    id="IsAmount"
                    name="IsAmount"
                    value="amount"
                    checked={IsPercentage==0} 
                    onChange={() => setIsPercentage(0)}
                  />
                  <label htmlFor="checkboxPrimary3" onClick={()=>setIsPercentage(0)}>Amount</label>
                </div>                
              </div>             

             
              {!showLoader ? (
                <button type="submit" className="btn btn-primary btn-space">
                  {SelCharge != null ? "Update" : "Submit"}
                </button>
              ) : (
                <button
                  className="btn btn-primary btn-space"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              )}

              <button
                type="reset"
                className="btn btn-danger btn-space"
                onClick={() => handleReset()}
              >
                Reset
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceChargeCrud;
