import AppConfig from "../../../Services/Util/AppConfig";
import React, { useState, useEffect } from "react";
import CopyLink from "./CopyPaymentLink";
import { Fomatter } from "../../../Services/Util/Formatter";
import "./DirectPayment.css";
import "./Paginate.css";
import ReactPaginate from 'react-paginate';
import ServiceClient from "../../../Services/ServiceFactory";
import { ServiceType } from "../../../Services/Util/ServiceType";
import "../../../TableStyle.css";
import DatePicker from "react-datepicker";

const DirectPaymentView = {
  Content: Content,
};



function Content({
  setEditDirectPayment,
  directPaymentList,
  setShowLoader,
  showLoader,
  setDeleteDirectPayment,
  agencyList,
  setIdEditedDPay,
  savedItem,
  setSavedItem,
  isRefresh,
  setIsRefresh,
  userList,
  fromDate,
  toDate,
  setFromDate,
  setToDate
}) {
  const [showCopyLinkModel, setShowCopyLinkModel] = useState(false);

  const [selDirectPayment, setSelDirectPayment] = useState(0);

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const DateFromUTC = (custDate) => {
    return new Date(Date.UTC(custDate.getFullYear(), custDate.getMonth(), custDate.getDate()));
  }

  function refresh() {    
    setToDate(DateFromUTC(new Date()));
    setFromDate(DateFromUTC(new Date(toDate.getFullYear(), toDate.getMonth(), 1)));
    setShowLoader(true);
    setIsRefresh(!isRefresh);
  }

  function Filter() {    
    setShowLoader(true);
    setIsRefresh(!isRefresh);
  }

  useEffect(() => {
    const itemsPerPage  = 10;
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(directPaymentList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(directPaymentList.length / itemsPerPage));
  }, [itemOffset, directPaymentList]);

  const handlePageClick = (event) => {
    const itemsPerPage  = 10;
    const newOffset = (event.selected * itemsPerPage) % directPaymentList.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if(savedItem>=0)
    {      
      if(savedItem>0)
      {
        setCopyDirectPaymentLink(savedItem);
      }
      else if(directPaymentList??directPaymentList[0].directPaymentId>0)
      {
        let id=directPaymentList[0].directPaymentId
        setCopyDirectPaymentLink(id);
      }
      setSavedItem();
    }
  });

  const printAsPDF = (id)=> {
    const pdfURL = AppConfig.baseURL + "api/direct-payment/manage-direct-payment/" + id + "/print-pdf";
    window.open(
      pdfURL,
      '_blank'
    );
  }
  const printAsHtml = (id)=> {
    ServiceClient.service(ServiceType.DirectPaymentService).PRINT(
      id,
      (res) => {
            var w = window.open(window.location.href,"_blank");
            w.document.open();
            w.document.write(res.data?.data);
            w.document.close();
            w.window.print();
            w.window.close();
      },
      (err) => {
        console.log(err);
      }
    );
    }

    const handleFromDateSelect = (date) => {
      if (!(date instanceof Date)) {
        date = new Date();
      }
      date = DateFromUTC(date);

      setFromDate(date);
      if (toDate == undefined || toDate < date) {
        setToDate(date);
      }
      
    };
  
    const handleToDateSelect = (date) => {
      if (!(date instanceof Date)) {
        date = new Date();
      }
      date = DateFromUTC(date);

      setToDate(date);
      if (fromDate == undefined) {
        setFromDate(date);
      }
    };

  function deleteDirectPayment(id) {
    const confirmBox = window.confirm(
      "Do you really want to delete this Direct Payment?"
    );
    if (confirmBox === true) {
      setDeleteDirectPayment(id);
    }
  }
  function setCopyDirectPaymentLink(id) {
    setSelDirectPayment(id);
    setShowCopyLinkModel(true);
  }

  function fetchEditData(item){
    setEditDirectPayment(item);
    //setIdEditedDPay(item.directPaymentId);

  };

   function getPaymentStatus(status)
   {
      switch(status)
      {
        case 0: return "Not Paid";
        case 1: return "Paid";
        case 2: return "Failed";
        case 3: return "PartiallyPaid";
        case 4: return "Initiated";
        case 5: return "Cancelled"

      }
   }
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Payment List</h3>&nbsp;&nbsp;&nbsp;
        <i className="fa fa-sync-alt" title="Refresh"
          onClick={() => refresh()}
        ></i>

        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-md-4">

              <div className="form-group">
              <label>From Date</label>
              <div
                className="input-group date"
                id="reservationdatetime"
                data-target-input="nearest"
              >
                <DatePicker
                  name="fromDate"
                  className="form-control"
                  dateFormat="dd-MMM-yyyy"
                  selected={fromDate}
                  onSelect={handleFromDateSelect} //when day is clicked
                />

              </div>
            </div>

            </div>
            <div className="col-md-4">

               <div className="form-group">
              <label>To Date</label>
              <div
                className="input-group date"
                id="reservationdatetime"
                data-target-input="nearest"
              >
                <DatePicker
                  name="ToDate"
                  className="form-control"
                  dateFormat="dd-MMM-yyyy"
                  minDate={fromDate}
                  selected={toDate}
                  onSelect={handleToDateSelect} //when day is clicked
                />

              </div>			 
            </div>
			
            </div>
            <div className="col-md-4">
            <label>&nbsp;</label>
              <div className="form-group">
              <button
                  className="btn btn-primary btn-space"
                  style={{ width: "100%" }}
                  tabIndex="1"
                  aria-hidden="true"
                  name=""
                  type="submit"
                  value="Filter"
                  onClick={() => Filter()}
                >Filter</button>
            </div>

            </div>
          </div>
      </div>

       
      <div className="card-body">
        {showLoader ? (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : currentItems.length > 0 ? (

          <div className="table-responsive">
         <table className="table text-center">
        <thead>
          <tr className="t-head-bg text-light">
            <th scope="col">#</th>
            <th scope="col">Agency</th>
            <th scope="col">Agent</th>
            <th scope="col">Name</th>
            <th scope="col">Expiry</th>
            <th scope="col">Amount</th>
            <th scope="col">Status</th>
            <th scope="col">Share</th>
            <th scope="col">Edit</th>
            <th scope="col">Print</th>
          </tr>
        </thead>
        <tbody className="tbody_strip">
		{currentItems.map((item, index) => (
          <tr key={index}>
            <td>{item.directPaymentId}</td>
            <td>{ agencyList!=''?agencyList.find(x => x.agencyId === item.agencyId)?.agencyName : item.agencyId }</td>
            <td>{ userList!=''?userList.find(x => x.userId === item.createdBy)?.userName : item.createdBy }</td>
            <td>{ item.customer?.customerName }</td>
            <td>{ Fomatter.Dateformat(item.expiry) }</td>
            <td>{item.amount + ' ' + item.currencyCode}</td>       
            <td><span className={item.paymentStatus === 0 ? "notpaid" : item.paymentStatus === 1 ? "paid" 
                      : item.paymentStatus === 2 || item.paymentStatus === 5 ? "notpaid" : "notpaid"}>{ getPaymentStatus(item.paymentStatus) }</span></td>
            <td> {
                    (item.isActive && item.paymentStatus != 1) ?
                      <i
                        className="fas fa-copy"
                        onClick={() =>
                          setCopyDirectPaymentLink(item.directPaymentId)
                        }
                      ></i>
                    : <span>&nbsp;</span>
                  }</td>
            <td>{
                      (item.paymentStatus == 0) ?
                    <i
                      style={{ cursor: "pointer" }}
                      className="fa fa-edit"
                      aria-hidden="true"
                      onClick={() => fetchEditData(item)}
                    ></i>
                    : <span>&nbsp;</span>
                    }</td>
			<td>
			{
                    item.paymentStatus == 1 
                    ? 
                    <span><i className="fa fa-print" title="Print"
                        onClick={() => printAsHtml(item.directPaymentId)}
                      ></i>&nbsp;&nbsp;
                      <i className="fa fa-file-pdf" title="PDF"
                        onClick={() => printAsPDF(item.directPaymentId)}
                      ></i></span>
                    : <span>&nbsp;</span>
                  }
			</td>
          </tr>    
     ))}		  
        </tbody>
      </table>

          </div>
        ) : (
          <div className="alert alert-danger"></div>
        )}
      </div>
      <div className="div-paginate">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<<"
          renderOnZeroPageCount={null}
        />
      </div>
      
      <CopyLink.Content
        showCopyLinkModel={showCopyLinkModel}
        setShowCopyLinkModel={setShowCopyLinkModel}
        selDirectPayment={selDirectPayment}
      />
    </div>
  );
}

export default DirectPaymentView;
