import HttpClient from "../APIService/RestAPI";

const ReportServiceClient = {
  DPayList: loadDirectPayments,
  DTransactionList: loadDirectPGTRansactions,
  DashBoradCountList: loadDashBoardCounts,
};

function loadDirectPayments(data, callback, errorcallback) {
  const dPayReportURL = "api/Reports/direct-payments";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.POST(dPayReportURL, data, config, callback, errorcallback);
}

function loadDirectPGTRansactions(data, callback, errorcallback) {
  const dTranReportURL = "api/Reports/payments-transactions";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.POST(dTranReportURL, data, config, callback, errorcallback);
}

function loadDashBoardCounts(callback, errorcallback) {
  const dDashboardURL = "api/Reports/dashboard-counts";

  var config = { "Access-Control-Allow-Origin": "*" };

  return HttpClient.GET(dDashboardURL,config, callback, errorcallback);
}

export default ReportServiceClient;
