import CookieStore from "./Storage/CookieStorageClient";
import LocalStore from "./Storage/LocalStorageClient";
import RedisStore from "./Storage/RedisStoargeClient";
import SessionStore from "./Storage/SessionStorageClient";
import { StorageType } from "./Util/ServiceType";

const StorageClient = {
  service: GetService,
};

function GetService(storageType) {
  switch (storageType) {
    case StorageType.Cookie:
      return CookieStore;

    case StorageType.Local:
      return LocalStore;

    case StorageType.Session:
      return SessionStore;

    case StorageType.Redis:
      return RedisStore;

    default:
      return LocalStore;
  }
}

export default StorageClient;
