import React, { useEffect, useState } from "react";
import AgencyDashBoardCount from "./AgencyDashBoardCount";
import ServiceClient,{} from "../Services/ServiceFactory";
import { ServiceType } from "../Services/Util/ServiceType";

export default function AgencyDashBoard({ token,selPage }) {
  
  const [agencyList, setAgencyList] = useState([]);
  useEffect(() => {
    selPage("Dashboard");;
  }, []);

  useEffect(() => {
    if(token)
    {
      ServiceClient.service(ServiceType.AgencyService).AgencyList(
        (res) => {
          setAgencyList(res?.data);
        },
        (err) => {
          console.log(err);
        }
      );
    }
    
  }, []);

  return (    
      <section className="content">
        <div className="container-fluid">
        <AgencyDashBoardCount token={token}></AgencyDashBoardCount>
          <section className="col-lg-5 connectedSortable">
            <div className="card bg-gradient-success"></div>
          </section>
        </div>
      </section>
  );
}
