import React, { useEffect, useState } from "react";
import ServiceClient from "../../../Services/ServiceFactory";
import { ServiceType } from "../../../Services/Util/ServiceType";
import { UserVM } from "../../../Model/UserVM";
import { MessageType } from "../../../Services/Util/MessageType";

const UserCrud = {
  Create: {
    Content: Content,
  },
};

function Content({
  SelUser,
  setEditUser,
  setUserList,
  showLoader,
  setShowLoader,
  deleteUser,
  setDeleteUser,
  isRefresh,
  setIsRefresh
}) {
  const [isChecked, setIsChecked] = useState(true);
  const [agencyList, setAgencyList] = useState([]);
  const [message, setMessage] = useState("");
  const [selAgency, setselAgency] = useState("");
  const [selsaluation, setselsaluation] = useState("");
  const [selmobileCode, setselmobileCode] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState("d-none");
  
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };


  function handleAgencychange(event) {
    setselAgency(event.target.value);
  }
  function handleSaluationchange(event) {
    setselsaluation(event.target.value);
  }
  function handleMobilecodechange(event) {
    setselmobileCode(event.target.value);
  }
  function handleReset() {
    setEditUser(null);
  }
  const userTitle = [
    { value: "0", label: "Mr" },
    { value: "1", label: "Mrs" },
    { value: "2", label: "Mstr" },
  ];

  const mobileCode = [
    { value: "0", label: "+91" },
    { value: "1", label: "+966" },
    { value: "2", label: "+916" },
  ];


  useEffect(() => {
    if (SelUser != null) {
      setIsChecked(SelUser.isActive);
      setselAgency(SelUser.agencyId);
      setselsaluation(SelUser.saluation);
      setselmobileCode(SelUser.mobileCode);
      window.scrollTo(0, 0);
    }
  }, [SelUser]);

  useEffect(() => {
    if (deleteUser != null) {
      ServiceClient.service(ServiceType.UserService).DELETE(
        deleteUser,
        (res) => {
          setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
        },
        (err) => {
          console.log(err);
        }
      );
      setDeleteUser(null);
    }
  }, [deleteUser, setMessage, setDeleteUser]);

  useEffect(() => {
    ServiceClient.service(ServiceType.AgencyService).AgencyList(
      (res) => {
        setAgencyList(res?.data?.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(() => {
    ServiceClient.service(ServiceType.UserService).UserList(
      (res) => {
        setUserList(res?.data?.data.filter(x => x.isActive == true));
        setShowLoader(false);
      },
      (err) => {
        console.log(err);
      }
    );
  }, [message,isRefresh]);

  const formSubmit = (e) => {
    e.preventDefault();
    let userData = new UserVM({
      userId: SelUser != null ? SelUser.userId : 0,
      userName: e.currentTarget.userName.value,
      userPassword: e.currentTarget.userPassword.value,
      saluation: e.currentTarget.userSaluation.value,
      firstName: e.currentTarget.userFirstName.value,
      middleName: '',
      lastName: e.currentTarget.userLastName.value,
      email: e.currentTarget.userEmail.value,
      mobileCode: e.currentTarget.userMobileCode.value,
      mobileNumber: e.currentTarget.userMobile.value,
      agencyId: e.currentTarget.agencyId.selectedOptions[0].value,
      isActive: e.currentTarget.userIsActive.value,
    });

    if (SelUser == null) {
      if (e.currentTarget.userPassword.value === e.currentTarget.userConfirmPassword.value) {
        setIsPasswordMatch("d-none");
        ServiceClient.service(ServiceType.UserService).POST(
          userData,
          (res) => {
            setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
            setShowLoader(false);
            e.target.reset();
            setEditUser(null);
            setselAgency(-1);
            setselsaluation(-1);
            setselmobileCode(-1);
            setShowLoader(true);
            setIsRefresh(!isRefresh);
          },
          (err) => {
            setShowLoader(false);
            console.log(err);
          }
        );
      }
      else {
        setIsPasswordMatch("");
      }
    }
    else {
      ServiceClient.service(ServiceType.UserService).PUT(
        userData,
        (res) => {
          setMessage(res?.data?.data ? MessageType.Success : MessageType.Fail);
          setShowLoader(false);
          e.target.reset();
          setEditUser(null);
          setselAgency(-1);
          setselsaluation(-1);
          setselmobileCode(-1);
          setShowLoader(true);
          setIsRefresh(!isRefresh);
        },
        (err) => {
          setShowLoader(false);
          console.log(err);
        }
      );
    }
  };

  return (
    <div className="card card-primary">
      <div className="card-header">
        <h3 className="card-title">Create User</h3>
        <div className="card-tools"></div>
      </div>
      <div className="card-body">
        <form onSubmit={formSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Agency</label>
                <select
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  data-select2-id="1"
                  tabIndex="1"
                  aria-hidden="true"
                  value={selAgency}
                  name="agencyId"
                  onChange={handleAgencychange}
                >
                  <option key={-1} defaultValue={-1} value={-1}>
                    Select
                  </option>
                  {agencyList.filter(x => x.isActive == true).map((obj) => (
                    <option
                      value={obj.agencyId}
                      key={obj.agencyId}
                      defaultValue={obj.agencyId}
                    >
                      {obj.agencyName}
                    </option>
                  ))}
                </select>
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>

              <div
                className="form-group"
                style={{ display: SelUser != null ? "none" : "block" }}
              >
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="userPassword"
                  tabIndex="3"
                  inputMode="password"
                  autoComplete="new-password"
                  defaultValue={SelUser != null ? SelUser.userPassword : ""}
                  required
                ></input>
              </div>
              <div className="form-group">
                <label>User Email</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-envelope"></i>
                    </span>
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    defaultValue={SelUser != null ? SelUser.email : ""}
                    name="userEmail"
                    inputMode="text"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label>First Name</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-server"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={SelUser != null ? SelUser.firstName : ""}
                    name="userFirstName"
                    inputMode="text"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Mobile Code</label>
                <select
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  data-select2-id="1"
                  tabIndex="-1"
                  aria-hidden="true"
                  value={selmobileCode}
                  name="userMobileCode"
                  onChange={handleMobilecodechange}
                >
                  <option key={-1} defaultValue={-1}>
                    Select
                  </option>
                  {mobileCode.map((obj) => (
                    <option
                      value={obj.value}
                      key={obj.value}
                      defaultValue={obj.value}
                    >
                      {obj.label}
                    </option>
                  ))}
                </select>
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>
              
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>User Name</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={SelUser != null ? SelUser.userName : ""}
                    readOnly={SelUser != null ? true : false}
                    name="userName"
                    inputMode="text"
                    required
                  />
                </div>
              </div>
              <div
                className="form-group"
                style={{ display: SelUser != null ? "none" : "block" }}
              >
                <label>Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  defaultValue={SelUser != null ? SelUser.userPassword : ""}
                  name="userConfirmPassword"
                  inputMode="password"
                  required
                ></input>
                <label name="errorPassword" className={isPasswordMatch} >Password Not Match</label>
              </div>
              <div className="form-group">
                <label>Title</label>
                <select
                  className="form-control select2 select2-hidden-accessible"
                  style={{ width: "100%" }}
                  data-select2-id="1"
                  tabIndex="5"
                  aria-hidden="true"
                  value={selsaluation}
                  name="userSaluation"
                  onChange={handleSaluationchange}
                >
                  <option key={-1} defaultValue={-1}>
                    Select
                  </option>
                  {userTitle.map((obj) => (
                    <option key={obj.value} defaultValue={obj.value}>
                      {obj.label}
                    </option>
                  ))}
                </select>
                <span
                  className="select2 select2-container select2-container--default"
                  dir="ltr"
                  data-select2-id="2"
                  style={{ width: "100%" }}
                ></span>
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-server"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={SelUser != null ? SelUser.lastName : ""}
                    name="userLastName"
                    inputMode="text"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label>User Mobile</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-phone"></i>
                    </span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    defaultValue={SelUser != null ? SelUser.mobileNumber : ""}
                    name="userMobile"
                    inputMode="number"
                    required
                  />
                </div>
              </div>






              
              
              

            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-xs-12">
            <div className="form-group clearfix">
                <label></label>
                <div className="icheck-primary d-inline">
                  <input
                    type="checkbox"
                    id="checkboxPrimary3"
                    disabled=""
                    checked={isChecked}
                    onChange={handleOnChange}
                    name="userIsActive"
                    value={isChecked}
                  />
                  <label htmlFor="checkboxPrimary3">IsActive</label>
                </div>
              </div>

            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-xs-12">
            {!showLoader ? (
                <button type="submit" className="btn btn-primary btn-space">
                  {SelUser != null ? "Update" : "Submit"}
                </button>
              ) : (
                <button
                  className="btn btn-primary btn-space"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              )}

              <button
                type="reset"
                className="btn btn-danger btn-space"
                onClick={() => handleReset()}
              >
                Reset
              </button>

            </div>
          </div>

          

        </form>
      </div>
    </div>
  );
}

export default UserCrud;
