const AppConfig = {
  // baseURL: "https://localhost:5127/",
  // paymentURL: "https://localhost:5127/",
  // baseURL: "http://beta1.travelboutiquekw.com/",
  // paymentURL: "http://beta1.travelboutiquekw.com/",
  baseURL: "https://coreapi.triotrip.com/",
  paymentURL: "https://link.triotrip.com/",
  dateFormat: "dd-MMM-yyyy",
  currencyFormat: "",
  refreshInterval:100
};

export default AppConfig;
