export class AgencyVM {
  constructor({agencyId, agencyname, email, country, mobile, address, logo, isActive}) {
    this.agencyId = agencyId;
    this.agencyname = agencyname;
    this.email = email;
    this.country = country;
    this.mobile = mobile;
    this.address = address;
    this.logo = logo;
    this.isActive = isActive;
  }
}
