import AgencyServiceClient from "./ServiceClient/AgencyService";
import LoginServiceClient from "./ServiceClient/LoginService";
import MasterServiceClient from "./ServiceClient/MasterService";
import UserServiceClient from "./ServiceClient/UserService";
import { ServiceType } from "./Util/ServiceType";
import RoleServiceClient from "./ServiceClient/RolesService";
import DireactPaymentServiceClient from "./ServiceClient/DirectPaymentService";
import GatewayServiceClient from './ServiceClient/GateWayService';
import ReportServiceClient from './ServiceClient/ReportService';


const ServiceClient = {
  service: GetService,
};

function GetService(serviceType) {
  switch (serviceType) {
    case ServiceType.AgencyService:
      return AgencyServiceClient;

    case ServiceType.UserService:
      return UserServiceClient;

    case ServiceType.Login:
      return LoginServiceClient;

    case ServiceType.MasterService:
      return MasterServiceClient;

    case ServiceType.GatewayService:
      return GatewayServiceClient;

    case ServiceType.RoleService:
      return RoleServiceClient;

    case ServiceType.DirectPaymentService:
      return DireactPaymentServiceClient;

    case ServiceType.ReportService:
        return ReportServiceClient;

    case ServiceType.RefreshToken:
      return LoginServiceClient;

    default:
      return UserServiceClient;
  }
}

export default ServiceClient;
